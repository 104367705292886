import * as Yup from 'yup'
import { KEY_ADDTIONAL_PEOPLE, KEY_FULLNAME, KEY_GENDER, KEY_RELATIONSHIP } from '../step1/formikConfig'
import { ALERT_EMPTY } from '../../../../../../components/insurance-app/buy-insurances-page/formik-config'
export const KEY_PACKAGE_SELECTED = 'productCode'
export const KEY_DURATION_SELECTED = 'duration'
export const KEY_START_INSURANCE = 'startValueDate'
export const KEY_END_INSURANCE = 'endValueDate'
export const KEY_YEAR = 'year'
export const KEY_ADD_ONS = 'addOns'
export const KEY_MONEY_LIFE = 'lifeValue'
export const KEY_MONEY_ACCIDENT = 'accidentValue'
export const KEY_CARD_TYPE = 'formatCard'
export const KEY_BEN_ID = 'idBeneficiari'

export const addtionalPeopleInitValue = {
    [KEY_BEN_ID] : '', 
    [KEY_PACKAGE_SELECTED]: '',
    [KEY_DURATION_SELECTED]: '',
    [KEY_START_INSURANCE] :'',
    [KEY_END_INSURANCE] : '',
    [KEY_ADD_ONS] : '',
    [KEY_MONEY_LIFE] : '',
    [KEY_MONEY_ACCIDENT] : '',
    [KEY_CARD_TYPE] : '',
    [KEY_FULLNAME] : '',
    [KEY_GENDER] : '',
    [KEY_RELATIONSHIP] : ''
  }

export const initialValues = {
    [KEY_ADDTIONAL_PEOPLE]: [{ ...addtionalPeopleInitValue }],
    [KEY_START_INSURANCE] : '',
    [KEY_END_INSURANCE] : '',
    [KEY_CARD_TYPE] : ''
}

export const validate = (values) => { }
export const validationSchema = Yup.object().shape({
  [KEY_ADDTIONAL_PEOPLE]: Yup.array().of(
    Yup.object().shape({
      [KEY_MONEY_LIFE]: Yup.string().required(ALERT_EMPTY),
      [KEY_MONEY_ACCIDENT]: Yup.string().required(ALERT_EMPTY),
    })
  ),
})
