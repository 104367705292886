import * as Yup from 'yup'
export const KEY_PACKAGE_SELECTED = 'productCode'
export const KEY_DURATION_SELECTED = 'duration'
export const KEY_START_INSURANCE = 'startValueDate'
export const KEY_END_INSURANCE = 'endValueDate'
export const KEY_YEAR = 'year'
export const KEY_ADD_ONS = 'addOns'


export const initialValues = {
    [KEY_PACKAGE_SELECTED]: '',
    [KEY_DURATION_SELECTED]: '',
    [KEY_START_INSURANCE] :'',
    [KEY_END_INSURANCE] : '',
    [KEY_ADD_ONS] : ''
}

export const validate = (values) => { }
export const validationSchema = Yup.object().shape({})
