import { BaseAppUltils, HttpClient } from 'base-app'
import { BH_URL, ELASTICSEARCH_URL } from '../../configs/app-no1'

class HomeService {

  static getRevenueChart(request) {
    return HttpClient.post(`${ELASTICSEARCH_URL}/revenue-chart`, request, {isBackgroundRequest: true})
  }

  static getDebtChart(request) {
    return HttpClient.post(`${ELASTICSEARCH_URL}/debt-chart`, request, {isBackgroundRequest: true})
  }

  static getBonusChart(request) {
    return HttpClient.post(`${ELASTICSEARCH_URL}/bonus-chart`, request, {isBackgroundRequest: true})
  }

  static getContractChart(request) {
    return HttpClient.post(`${ELASTICSEARCH_URL}/contract-chart`, request, {isBackgroundRequest: true})
  }

  static getAccountChart(request) {
    return HttpClient.post(`${ELASTICSEARCH_URL}/account-chart`, request, {isBackgroundRequest: true})
  }

  static getCountAccountApproved(request) {
    return HttpClient.post(`${ELASTICSEARCH_URL}/users/count-account-approved`, request, {isBackgroundRequest: true})
  }

  static getCountAccountAwaitingApproval() {
    return HttpClient.get(`${ELASTICSEARCH_URL}/users/count-account-awaiting-approval`, { params: { uuid: BaseAppUltils.generateUUID() }, isBackgroundRequest: true })
  }

  static getCountContractAwaitingApproval() {
    return HttpClient.get(`${ELASTICSEARCH_URL}/contracts/count-contract-awaiting-approval`, { params: { uuid: BaseAppUltils.generateUUID() }, isBackgroundRequest: true })
  }

  static getCountContractExpired() {
    return HttpClient.get(`${ELASTICSEARCH_URL}/contracts/count-contract-expired`, { params: { uuid: BaseAppUltils.generateUUID() }, isBackgroundRequest: true })
  }

  static getCountContractPendingBH() {
    return HttpClient.get(`${BH_URL}`, { params: { uuid: BaseAppUltils.generateUUID() }, isBackgroundRequest: true })
  }

  static getDebtInfo() {
    return HttpClient.get(`${ELASTICSEARCH_URL}/debt-info`, { params: { uuid: BaseAppUltils.generateUUID() }, isBackgroundRequest: true })
  }

  static getDebitedAccount() {
    return HttpClient.get(`${ELASTICSEARCH_URL}/debt/count-account-debt`, { params: { uuid: BaseAppUltils.generateUUID() }, isBackgroundRequest: true })
  }

  static getTop10HighestRevenue(timeType) {
    return HttpClient.get(`${ELASTICSEARCH_URL}/revenue/top10best`, {
      params: {
        timeType: timeType,
        uuid: BaseAppUltils.generateUUID()
      },
      isBackgroundRequest: true
    })
  }

  static getTop10LowestRevenue(timeType) {
    return HttpClient.get(`${ELASTICSEARCH_URL}/revenue/top10worst`, {
      params: {
        timeType: timeType,
        uuid: BaseAppUltils.generateUUID()
      },
      isBackgroundRequest: true
    })
  }
}

export default HomeService
