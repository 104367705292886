import Service from '../../../services/insurance-app/buyInsuranceTD'
import { hasRequestFail } from '../../../ultity'
import {
  ACTION_BUY_INSUR_TD_RESET_ALL, ACTION_BUY_INSUR_TD_UPDATE_PROPS,
  BASE,
  KEY_CONTRACT_CODE,
  KEY_CONTRACT_ID,
  KEY_DATA_FEES,
  KEY_HAS_CAL_FEE_DONE,
  MAX_STEP, KEY_CLIENT_MESSAGE_ID, KEY_ACTIVE_STEP, KEY_PAY_CONTRACT_STATUS,
  KEY_STEP_1, KEY_STEP_2
} from '../../reducers/insurance-app/buyInsurancesTD'
import { KEY_BUY_PEOPLE, KEY_ADDTIONAL_PEOPLE, KEY_REFERRER, KEY_SELF_BENEFICIARY, KEY_IC_TYPE, KEY_IC_NO, 
  KEY_FULLNAME, KEY_DATE_BIRTH, KEY_GENDER, KEY_PHONE_NUMBER, KEY_EMAIL, KEY_CITY, KEY_DISTRICT, KEY_ADDRESS, 
  KEY_WARD, KEY_ADDRESS_TAX, KEY_WARD_TAX, KEY_DISTRICT_TAX, KEY_CITY_TAX, KEY_BUYER_TYPE, KEY_FAX_CODE
} from '../../../pages/insurance-app/buy-insurances/personal-taichinhtoandien/components/step1/formikConfig'
import { setLoadingStatus } from './appConfig'
import { PAID_WAITING } from "../../../configs/insurance-app"
import { BaseAppUltils } from "base-app"
import moment from 'moment'
import { CORP, CORPORATE } from '../../../pages/insurance-app/buy-insurances/personal-taichinhtoandien/components/step1/utility'
import { 
  KEY_DATE_INSUR_FROM,
  KEY_DATE_INSUR_TO, 
  KEY_DURATION
} from '../../../pages/insurance-app/buy-insurances/personal-taichinhtoandien/components/step2/formikConfig'
import { KEY_PAYMENT_TYPE } from '../../reducers/insurance-app/utility'

const formatBirthday = (dateString) => {
  if (!dateString) return ''

  if (typeof dateString === 'string' && dateString.match(/^\d{2}\/\d{2}\/\d{4}$/)) {
    return dateString
  }
  let momentDate
  try {
    if (!isNaN(dateString)) {
      momentDate = moment(Number(dateString))
    } else {
      momentDate = moment(dateString, [
        'DD/MM/YYYY',
        'YYYY-MM-DD',
        'YYYY/MM/DD',
        'MM/DD/YYYY',
        'DD-MM-YYYY'
      ], true)
    }

    if (!momentDate.isValid()) {
      return ''
    }

    const formattedDate = momentDate.format('DD/MM/YYYY')
    return formattedDate
  } catch (error) {
    return ''
  }
}

// Hàm utility để format ngày theo ISO-8601 (cho Instant)
const formatInstantDate = (dateString) => {
  if (!dateString) return ''
  
  const momentDate = moment(dateString)
  if (!momentDate.isValid()) {
    return ''
  }

  const formattedDate = momentDate.utc().format()
  return formattedDate
}

// Hàm chính để chuẩn bị dữ liệu contract
const getContractInfo = (stepData, refId = null, contractId = null) => {
  
  const step1Data = stepData[KEY_STEP_1] || {}
  const step2Data = stepData[KEY_STEP_2] || {}
  const buyerInfo = step1Data[KEY_BUY_PEOPLE]?.[0] || {}
  const insuredInfo = step1Data[KEY_ADDTIONAL_PEOPLE]?.[0] || {}

  // Chuẩn bị thông tin người được bảo hiểm
  const insuredPerson = {
    customerType: "INDIVIDUAL",
    icType: insuredInfo[KEY_IC_TYPE],
    icNo: insuredInfo[KEY_IC_NO],
    fullName: insuredInfo[KEY_FULLNAME],
    selfBeneficiary: insuredInfo[KEY_SELF_BENEFICIARY] || true,
    birthday: formatBirthday(insuredInfo[KEY_DATE_BIRTH]),  // DD/MM/YYYY
    gender: insuredInfo[KEY_GENDER],
    phoneNumber: insuredInfo[KEY_PHONE_NUMBER],
    email: insuredInfo[KEY_EMAIL],
    province: insuredInfo[KEY_CITY],
    district: insuredInfo[KEY_DISTRICT],
    addressDetail: insuredInfo[KEY_ADDRESS]
  }

  // Chuẩn bị thông tin chủ hợp đồng
  const policyOwner = {
    customerType: buyerInfo[KEY_BUYER_TYPE] || "INDIVIDUAL",
    icType: buyerInfo[KEY_BUYER_TYPE] === CORPORATE ? "MST" : buyerInfo[KEY_IC_TYPE],
    icNo: buyerInfo[KEY_IC_NO],
    email: buyerInfo[KEY_EMAIL],
    fullName: buyerInfo[KEY_FULLNAME],
    gender: buyerInfo[KEY_GENDER],
    phoneNumber: buyerInfo[KEY_PHONE_NUMBER],
    birthday: formatBirthday(buyerInfo[KEY_DATE_BIRTH]),  // DD/MM/YYYY
    province: buyerInfo[KEY_CITY],
    addressDetail: buyerInfo[KEY_ADDRESS],
    district: buyerInfo[KEY_DISTRICT],
    ward: buyerInfo[KEY_WARD]
  }

  // Thêm thông tin bổ sung cho doanh nghiệp
  if (buyerInfo[KEY_BUYER_TYPE] === CORPORATE) {
    policyOwner.fax = buyerInfo[KEY_FAX_CODE]
    policyOwner.addressTax = [
      buyerInfo[KEY_ADDRESS_TAX],
      buyerInfo[KEY_WARD_TAX],
      buyerInfo[KEY_DISTRICT_TAX],
      buyerInfo[KEY_CITY_TAX]
    ].filter(Boolean).join(', ')
  }

  // Chuẩn bị thông tin bảo hiểm
  const insuranceDetails = {
    startDate: moment(step2Data[KEY_DATE_INSUR_FROM])
        .startOf('day')
        .utc(true)
        .toISOString(),
    rangeDate: step2Data[KEY_DURATION],
    endDate: moment(step2Data[KEY_DATE_INSUR_TO])
        .startOf('day')
        .utc(true)
        .toISOString(),
    insuranceAmount: Number(step2Data.insuranceAmount),
    feeRate: `${step2Data.feeRate}%`,
    totalFee: step2Data.totalFee.toString(),
    creditContractNumber: step2Data.creditContractNumber
  }

  // Tạo object contract info
  const contractInfo = {
    cif: '1',
    referrer: step1Data[KEY_REFERRER] || '',
    refId: refId,
    paymentType: step1Data.paymentType || "FUND_TRANSFER",
    insuredPersons: insuredPerson,
    policyOwner: policyOwner,
    insuranceDetails: insuranceDetails
  }

  return contractInfo
}

export const createContract = (stepData) => {
  return async (dispatch) => {
    try {
      dispatch(setLoadingStatus(true))
      const contractInfo = getContractInfo(stepData)
      
      const response = await Service.createContract(contractInfo)
      dispatch(setLoadingStatus(false))

      if (hasRequestFail(response.status)) {
        return
      }

      if (200 === response.status) {
        dispatch(updateProps([
          {
            prop: KEY_CONTRACT_ID,
            value: response.data.data.id
          },
          {
            prop: KEY_CONTRACT_CODE,
            value: response.data.data.contractCode
          }
        ]))
      }
    } catch (e) {
      // console.log('Lỗi ngoại lệ khi tạo hợp đồng:', e)
      dispatch(setLoadingStatus(false))
    }
  }
}

export const updateProps = (properties) => {
  return (dispatch) => {
    dispatch(
      {
        type: ACTION_BUY_INSUR_TD_UPDATE_PROPS,
        payload: properties
      }
    )
  }
}

export const resetState = () => {
  return (dispatch) => {
    dispatch({
      type: ACTION_BUY_INSUR_TD_RESET_ALL,
    })
  }
}

export const backStep = (currentStep) => {
  return (dispatch) => {
    let _nextStep = --currentStep
    if (_nextStep < 1) {
      _nextStep = 1
    }

    dispatch(
      {
        type: ACTION_BUY_INSUR_TD_UPDATE_PROPS,
        payload: [
          {
            prop: BASE.KEY_ACTIVE_STEP,
            value: _nextStep
          },
          {
            prop: BASE.KEY_HAS_CAL_FEE_DONE,
            value: false
          },
        ]
      }
    )
  }
}

export const nextStep = (currentStep) => {
  return (dispatch) => {
    let nextStepValue = currentStep + 1
    if (nextStepValue > MAX_STEP) {
      nextStepValue = MAX_STEP
    }
    
    dispatch(updateProps([
      {
        prop: KEY_ACTIVE_STEP,
        value: nextStepValue
      }
    ]))
  }
}

export const updateContract = (contractId, stepData) => {
  return async (dispatch) => {
    try {
      dispatch(setLoadingStatus(true))
      const contractInfo = getContractInfo(stepData, null, contractId)
      
      const response = await Service.updateContract(contractInfo, contractId)
      dispatch(setLoadingStatus(false))

      if (hasRequestFail(response.status)) {
        return
      }

    } catch (e) {
      // console.log('Lỗi ngoại lệ khi cập nhật hợp đồng:', e)
      dispatch(setLoadingStatus(false))
    }
  }
}

export const getFee = (contactId) => {
  return async (dispatch) => {
    dispatch(setLoadingStatus(true));
    const res = await Service.getFee(contactId);
    dispatch(setLoadingStatus(false));
    if (hasRequestFail(res.status)) {
      return;
    }
    dispatch({
      type: ACTION_BUY_INSUR_TD_UPDATE_PROPS,
      payload: [
        {
          prop: KEY_HAS_CAL_FEE_DONE,
          value: true
        },
        {
          prop: KEY_DATA_FEES,
          value: res.data
        },
      ]
    });
  };
};

export function pay(res, keyMaps = {}) {
  return async (dispatch) => {
    const { ACTION_BUY_INSUR_TD_UPDATE_PROPS, KEY_PAY_CONTRACT_STATUS } = keyMaps
    if (hasRequestFail(res.status)) return
    
    const { url } = res.data
    if (url) {
      window.location.assign(url)
    } else {
      dispatch({
        type: ACTION_BUY_INSUR_TD_UPDATE_PROPS,
        payload: [
          {
            prop: KEY_PAY_CONTRACT_STATUS,
            value: PAID_WAITING
          }
        ]
      })
    }
  }
}

export function findOne(contractId, setContract, setContractValue) {
  return async (dispatch) => {
    dispatch(setLoadingStatus(true));
    const response = await Service.getContractDetails(contractId);
    dispatch(setLoadingStatus(false))
    if (hasRequestFail(response.status)) {
      return;
    }
    if (response?.data?.data) {
      setContract(response.data);
      setContractValue(response.data.data.totalFeeInclVAT)
    }
  }
}

export function updatePaymentType(contractId, paymentType) {

  const payload = {
    contractId,
    paymentType
  }

  return async (dispatch) => {
    dispatch(setLoadingStatus(true));
    const response = await Service.updatePaymentInfo(payload);
    dispatch(setLoadingStatus(false));

    if (hasRequestFail(response.status)) {
      return
    }

    dispatch(updateProps([{
        prop: KEY_PAYMENT_TYPE,
        value: response.data.data.paymentType
    }]))
  }
}

export function payContract(contractId, paymentType) {
  return async (dispatch) => {
    try {
      dispatch(setLoadingStatus(true))
      const response = await Service.payContract(contractId, paymentType)
      dispatch(setLoadingStatus(false))

      if (hasRequestFail(response.status)) {
        return
      }

      if (response.status === 200) {
        dispatch(
          pay(response, {
            ACTION_BUY_INSUR_TD_UPDATE_PROPS,
            KEY_ACTIVE_STEP,
            MAX_STEP,
            KEY_PAY_CONTRACT_STATUS
          })
        )

        dispatch(updateProps([
          {
            prop: KEY_ACTIVE_STEP,
            value: 4
          },
          {
            prop: KEY_PAY_CONTRACT_STATUS,
            value: PAID_WAITING
          }
        ]))
        const { url } = response.data
        if (url) {
          window.location.assign(url)
        }
      }
    } catch (error) {
      // console.log('Lỗi khi thanh toán:', error)
      dispatch(setLoadingStatus(false))
    }
  }
}

export const getProposalForm = (contractCode) => {
  return async (dispatch) => {
    try {
      dispatch(setLoadingStatus(true))
      const response = await Service.getProposalForm(contractCode)
      dispatch(setLoadingStatus(false))
      return response
    } catch (error) {
      dispatch(setLoadingStatus(false))
      console.error("Lỗi khi lấy yêu cầu bảo hiểm:", error)
      throw error
    }
  }
}
