import { BaseAppUltils, FormattedMessage, HttpClient } from 'base-app'
import moment from 'moment'
import React from 'react'
import {
  GENDER_TYPE_FEMALE,
  GENDER_TYPE_MALE
} from '../../../../../../components/insurance-app/buy-insurances-page/formik-config'
import { NAME_BUY_INSURANCES_BVAG, getKeyLang } from '../../../../../../configs/insurance-app'
import { updateProps } from '../../../../../../redux/actions/insurance-app/buyInsurancesBvag'
import {
  BASE,
  KEY_DATA_FEE_FOR_PERSON,
  KEY_INSURANCE_INFO,
  KEY_IS_BUY_AGAIN_CONTRACT,
  KEY_STEP_2
} from '../../../../../../redux/reducers/insurance-app/buyInsurancesBvag'
import {
  KEY_ACTIVE_STEP,
  KEY_COMPANY_ID,
  KEY_CONTRACT_CODE,
  KEY_CONTRACT_INFO,
  KEY_TOTAL_FEE
} from '../../../../../../redux/reducers/insurance-app/utility'
import { hasRequestFail, isArrayEmpty } from '../../../../../../ultity'
import {
  KEY_ADDTIONAL_PEOPLE,
  KEY_DATE_BIRTH,
  KEY_GENDER,
  KEY_RELATIONSHIP
} from '../step1/formikConfig'
import {
  KEY_ADD_ONS,
  KEY_BEN_ID,
  KEY_CARD_TYPE,
  KEY_CAR_TYPE,
  KEY_END_INSURANCE,
  KEY_MONEY_ACCIDENT,
  KEY_MONEY_LIFE,
  KEY_PACKAGE_SELECTED,
  KEY_START_INSURANCE
} from './formikConfig'
import { check6age } from '../step1/utility'
export const API_INSURANCES_HCAC = '/nth/personalinsurance/api/ba-insurance'
export const API_CONTRACTS_HCAC_FEE_INSURANCE_ =
  '/nth/personalinsurance/api/contract/happy-life/fee'

/** 
 * @example
 *  data = {
      "packageName": "GOI3",
      "duration": 12
    } 
*/
export const BRONZE = 'BRONZE'
export const SILVER = 'SILVER'
export const TITAN = 'TITAN'
export const GOLD = 'GOLD'
export const PLATINUM = 'PLATINUM'
export const DIAMOND = 'DIAMOND'
export const LUXURY = 'LUXURY'

export const updateInsurancePackages = (contractId, data) => {
  return async (dispatch) => {
    try {
      const res = await HttpClient.post(
        `${API_INSURANCES_HCAC}?contractId=${contractId}`,
        data
      )
      if (res.status === 200) {
        dispatch(
          updateProps([
            {
              prop: BASE.KEY_ACTIVE_STEP,
              value: 2
            },
            {
              prop: KEY_INSURANCE_INFO,
              value: res.data
            }
          ])
        )
      }
    } catch (e) {
      console.log(e)
    }
  }
}
export const updateInsurance = (contractId, data) => {
  return async (dispatch) => {
    try {
      const res = await HttpClient.put(
        `${API_INSURANCES_HCAC}?contractId=${contractId}`,
        data
      )
      if (hasRequestFail(res.status)) return

      const res2 = await HttpClient.get(
        `${API_CONTRACTS_HCAC_FEE_INSURANCE_}?contractId=${contractId}`,
        data
      )
      if (hasRequestFail(res.status)) return

      dispatch(
        updateProps([
          {
            prop: BASE.KEY_ACTIVE_STEP,
            value: 3
          },
          {
            prop: KEY_INSURANCE_INFO,
            value: res.data
          },
          {
            prop: BASE.KEY_DATA_FEES,
            value: res2.data
          }
        ])
      )
    } catch (e) {
      console.log(e)
    }
  }
}
export const getBodyDateInsurance = (start, end) => {
  return {
    startValueDate: moment(start).toISOString(),
    endValueDate: moment(end).toISOString()
  }
}

export const updateInsuranceAndFeeStep2 = (
  contractId,
  data,
  setCompaniesData,
  companyId,
  contractInfo,
  _addOns , 
  values
) => {
  return async (dispatch , getState) => {
    try {
      const { [KEY_IS_BUY_AGAIN_CONTRACT] : buyAgainContract} = getState()["app"][NAME_BUY_INSURANCES_BVAG]
      const res = await HttpClient.put(
        `${API_INSURANCES_HCAC}?contractId=${contractId}`,
        data
      )
      if (res.status !== 200) return
      const totalFee = res.data.length > 1 ? res.data.reduce((acc, obj) => acc + obj.totalValueInsurance, 0) : res.data[0].totalValueInsurance
      const mainValueInsurance = res.data.length > 1 ? res.data.reduce((acc, obj) => acc + obj.mainValueInsurance, 0) : res.data[0].mainValueInsurance
      if (res.status === 200) {
        const newDataFee = [
          {
            companyId: '07',
            feeInsurances: {
              TPBANKPayFee: 0,
              mainFee: mainValueInsurance,
              OT:
                 getFeeByTypeAddon(res.data , 'BA_OT')||
                undefined,
              DD:
              getFeeByTypeAddon(res.data , 'BA_DD') ||
                undefined,
              A:
              getFeeByTypeAddon(res.data , 'BA_A')
               ||
                undefined,
              DC:
              getFeeByTypeAddon(res.data , 'BA_DC') ||
                undefined,
              M:
              getFeeByTypeAddon(res.data , 'BA_M')||
                undefined,
              FUND_TRANSFERPayFee: 0,
              QR_CODEPayFee: 0,
              BONUSPayFee: 0,
              MBBANKPayFee: 0,
              VISA_MASTERPayFee: 0,
              VOUCHERPayFee: 0,
              feeInsurance: totalFee,
              ATMPayFee: 0,
              DEBTPayFee: 0
            },
            nameDetail: 'Tổng công ty bảo hiểm Bảo Việt',
            order: 1
          }
        ]
        dispatch(
          updateProps([
            {
              prop: KEY_INSURANCE_INFO,
              value: res.data
            },
            {
              prop: KEY_STEP_2,
              value: values
            },
            {
              prop: KEY_TOTAL_FEE,
              value: totalFee
            },
            {
              prop: BASE.KEY_DATA_FEES,
              value: newDataFee
            },
            {
              prop : KEY_DATA_FEE_FOR_PERSON, 
              value : res.data
            }
          ])
        )
        // IF ACTION BUY NEW AGAIN => NEXT STEP 3
        if (buyAgainContract) {
          dispatch(
            updateProps([
              {
                prop : KEY_ACTIVE_STEP, 
                value : 3
              },
              {
                prop : KEY_IS_BUY_AGAIN_CONTRACT , 
                value : false
              }
            ])
          )
        }
      }
    } catch (e) {
      console.log(e)
    }
  }
}

export const getDefault_updateCompanyIdContractObj = (
  contractInfo,
  companyId
) => {
  let _contractInfo = { ...contractInfo }
  delete _contractInfo['owner']
  delete _contractInfo['beneficiaries']
  delete _contractInfo['paymentType']
  delete _contractInfo['totalFeeInclVAT']
  _contractInfo['companyId'] = companyId
  return _contractInfo
}

export const updateCompanyId = (contractId, data, companyId) => {
  return async (dispatch) => {
    const res = await HttpClient.put(
      `/nth/personalinsurance/api/contract/happy-life/company?contractId=${contractId}&companyId=${companyId}`,
      { id: contractId },
      {
        headers: { insuranceType: 'happy-life' }
      }
    )
    if (res.status === 200) {
      dispatch(
        updateProps([
          {
            prop: KEY_COMPANY_ID,
            value: companyId
          },
          {
            prop: KEY_CONTRACT_CODE,
            value: res.data.contractCode
          },
          {
            prop: KEY_CONTRACT_INFO,
            value: res.data
          }
        ])
      )
    } else {
      BaseAppUltils.toastError(
        'Cập nhật hãng bảo hiểm thất bại , vui lòng thử lại sau !'
      )
    }
  }
}

export const DURATION_PACKAGE = [
  {
    value: 12,
    year: 1,
    title: <FormattedMessage id={getKeyLang('insurance.heath.1year')} />
  },
  {
    value: 24,
    year: 2,
    title: <FormattedMessage id={getKeyLang('insurance.heath.2year')} />
  },
  {
    value: 36,
    year: 3,
    title: <FormattedMessage id={getKeyLang('insurance.heath.3year')} />
  }
]

export const objectUpdate_Insurance = (
  values,
  contractId,
  birthday,
  addOns
) => {
  const newBody = values[KEY_ADDTIONAL_PEOPLE].map((_elt, index) => {
    let newAddons
    if (!isArrayEmpty(_elt[KEY_ADD_ONS])) {
      newAddons = _elt[KEY_ADD_ONS].filter((_elt)=> {
        if (_elt.isEnable) {
          return _elt.addonCode
        }
      }).map(_addons => _addons.addonCode)
    }
    // //if date now => time now <==> 00:00:00
    const _startDate = moment(values[KEY_START_INSURANCE])
      .utc(true)
      .format(`YYYY-MM-DD 00:00:00`)
    const _endDate = moment(values[KEY_END_INSURANCE])
      .utc(true)
      .format(`YYYY-MM-DD 23:59:59`)
    return {
      productCode: _elt[KEY_PACKAGE_SELECTED],
      description: 'Bảo hiểm Bảo Việt An Gia',
      birthday: _elt[KEY_DATE_BIRTH],
      isEnable: true,
      duration: 12,
      startValueDate: moment(_startDate).utc(true).toISOString(),
      endValueDate: moment(_endDate).utc(true).toISOString(),
      contractId: contractId,
      insuranceCode: 'BA',
      lifeValue: !isArrayEmpty(_elt[KEY_ADD_ONS]) && newAddons.some((_addon) => _addon === 'DD')
        ? _elt[KEY_MONEY_LIFE]
        : undefined,
      accidentValue: !isArrayEmpty(_elt[KEY_ADD_ONS]) && newAddons.some((_addon) => _addon === 'A')
        ? _elt[KEY_MONEY_ACCIDENT]
        : undefined,
      addOnCode: newAddons,
      formatCard: values[KEY_CARD_TYPE],
      companayId: '07',
      idBeneficiary: _elt.idBeneficiari
    }
  })
  return newBody
}

export const _RANGE = [
  {
    value: 'BASIC',
    title: 'heath-care-advanced.range.bassic'
  },
  {
    value: 'ADVANCE',
    title: 'heath-care-advanced.range.advanced'
  },
  {
    value: 'BASIC',
    title: 'heath-care-advanced.range.bassic'
  },
  {
    value: 'ADVANCE',
    title: 'heath-care-advanced.range.advanced'
  }
]
export const _LIFE_OPTIONS = [
  {
    label: '20 triệu',
    value: 20000000
  },
  {
    label: '50 triệu',
    value: 50000000
  },
  {
    label: '100 triệu',
    value: 100000000
  },
  {
    label: '150 triệu',
    value: 150000000
  },
  {
    label: '200 triệu',
    value: 200000000
  },
  {
    label: '250 triệu',
    value: 250000000
  },
  {
    label: '300 triệu',
    value: 300000000
  }
]
export const _ACCIDENT_OPTIONS = [
  {
    label: '20 triệu',
    value: 20000000
  },
  {
    label: '50 triệu',
    value: 50000000
  },
  {
    label: '100 triệu',
    value: 100000000
  },
  {
    label: '200 triệu',
    value: 200000000
  },
  {
    label: '300 triệu',
    value: 300000000
  },
  {
    label: '400 triệu',
    value: 400000000
  },
  {
    label: '500 triệu',
    value: 500000000
  },
  {
    label: '600 triệu',
    value: 600000000
  },
  {
    label: '700 triệu',
    value: 700000000
  },
  {
    label: '800 triệu',
    value: 800000000
  },
  {
    label: '900 triệu',
    value: 900000000
  },
  {
    label: '1 tỷ',
    value: 1000000000
  }
]
export const Insurance_addOns = [
  {
    addonCode: 'OT',
    description: 'Quyền lợi Điều trị ngoại trú',
    isEnable: false,
    notApplyFor: [],
    minAge: 0,
    maxAge: 99,
    checkAge: false,
    gender: [GENDER_TYPE_MALE, GENDER_TYPE_FEMALE]
  },
  {
    addonCode: 'DC',
    description: 'Quyền lợi nha khoa',
    isEnable: false,
    notApplyFor: [],
    minAge: 0,
    maxAge: 99,
    checkAge: false,
    gender: [GENDER_TYPE_MALE, GENDER_TYPE_FEMALE]
  },
  {
    addonCode: 'M',
    description: 'Quyền lợi thai sản',
    isEnable: false,
    notApplyFor: [BRONZE],
    minAge: 18,
    maxAge: 45,
    checkAge: true,
    gender: [GENDER_TYPE_FEMALE]
  },
  {
    addonCode: 'DD',
    description: 'Bảo hiểm sinh mạng cá nhân',
    isEnable: false,
    notApplyFor: [],
    minAge: 18,
    maxAge: 45,
    checkAge: true,
    gender: [GENDER_TYPE_MALE, GENDER_TYPE_FEMALE],
    placeholderOption: 'bvag.step2.lifeOption',
    keyFormik: KEY_MONEY_LIFE
  },
  {
    addonCode: 'A',
    description: 'Bảo hiểm tai nạn cá nhân',
    isEnable: false,
    notApplyFor: [],
    minAge: 18,
    maxAge: 45,
    checkAge: true,
    gender: [GENDER_TYPE_MALE, GENDER_TYPE_FEMALE],
    placeholderOption: 'bvag.step2.accident',
    keyFormik: KEY_MONEY_ACCIDENT
  }
]
export const checkAgeMinToMaxForType = (
  person,
  min = 15,
  max = 365,
  type = 'days'
) => {
  const values = moment().diff(
    moment(person.dateOfBirth).format('YYYY-MM-DD'),
    type
  )
  if (values >= min && values < max) {
    return true
  }
}

export const filterByAge = (arr) => {
  const today = moment();
  const fifteenYearsAgo = moment(today).subtract(15, 'years');

  return arr.some((item) => {
    const dateOfBirth = moment(item.dateOfBirth);
    const ageInDays = today.diff(dateOfBirth, 'days');

    return ageInDays < 365 * 15 && ageInDays > 365;
  });
}
export const checkAgeAndGenderMinToMaxForType = (person) => {
  const age = moment(new Date).diff(
    moment(person.dateOfBirth).format('YYYY-MM-DD'),
    'years' , true
  )
  if (age < 18 || age > 45 ||  person.gender !== GENDER_TYPE_FEMALE) {
    return true
  }
}
export const checkAgeMin15ToMax365ForType = (bens) => {
  let result
  bens.forEach((person) => {
    const age = moment().diff(
      moment(person.dateOfBirth).format('YYYY-MM-DD'),
      'days' , true
    )
    if (age >= 15 && age < 365) {
      result = true
    }
  })
  return result
}
export const HARD_CARD = 'HARD_CARD'
export const ELECTROMAGNETIC_CARD = 'E_CARD'
export const _INSURANCE_TYPES = [
  {
    content: 'Thẻ điện tử',
    value: ELECTROMAGNETIC_CARD
  },
  {
    content: 'Thẻ cứng',
    value: HARD_CARD
  }
]
export const getFeeByTypeAddon = (fees, type) => {
  let addonValueSum = 0;
  fees.forEach((obj) => {
    obj.addOn.forEach((addon) => {
      if (addon.code === type && addon.value !== null) {
        addonValueSum += addon.value;
      }
    });
  });
return addonValueSum
}
export const checkDCaddOn = (person) => {
  const age = moment().diff(
    moment(person.dateOfBirth).format('YYYY-MM-DD'),
    'years' , true
  )
  if (age < 1 || age > 65) {
    return true
  }
}
export const MAX_ACCIDENT = 1000000000
export const MAX_LIFE = 300000000
export const MIN_VALUES = 20000000

export const resultValuePackageNumber = (packageName) => {
  let numberPackage 
  switch (packageName) {
    case BRONZE:
      numberPackage = 1
      break;
    case SILVER:
      numberPackage = 2
      break;
    case GOLD:
      numberPackage = 3
      break;
    case PLATINUM:
      numberPackage = 4
      break;
    case DIAMOND:
      numberPackage = 5
      break;
    default:
      numberPackage = 1
      break;
  }
  return numberPackage
}

export const sortByDateOfBirthAscending = (arr) => {
  return arr.sort((a, b) => {
    const dateA = new Date(a.dateOfBirth);
    const dateB = new Date(b.dateOfBirth);
    return dateA - dateB ;
  });
}

export const findIndexesWithRelationship = (data, relationship) =>  {
  return data.reduce((indexes, item, index) => {
    if (item.relationship === relationship && check6age(data)) {
      indexes.push(index);
    }
    return indexes;
  }, []);
}