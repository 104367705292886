import {
    PAYMENT_TYPE_ATM,
    PAYMENT_TYPE_CASH
} from '../../../components/insurance-app/buy-insurances-page/formik-config'
import { NAME_BUY_INSURANCES_HOME_ANCU, COMPANIES, PAID_WAITING } from "../../../configs/insurance-app"
import {
    KEY_BUYER_IC_TYPE,
    KEY_BUYER_IC_NO,
    KEY_BUYER_FULLNAME,
    KEY_BUYER_PHONE_NUMBER,
    KEY_BUYER_EMAIL,
    KEY_BUYER_GENDER,
    KEY_BUYER_DATE_BIRTH,
    KEY_BUYER_CITY,
    KEY_BUYER_DISTRICT,
    KEY_BUYER_WARD,
    KEY_BUYER_ADDRESS
} from '../../../pages/insurance-app/buy-insurances/ancu-home/components/step1/formikConfig'
import { ACTION_MERGE_PROPS, ACTION_RESET_ALL, ACTION_UPDATE_PROPS } from "../../actions/insurance-app/buyAnCuInsurances"

export const REDUX_STATE_NAME = NAME_BUY_INSURANCES_HOME_ANCU
export const MAX_STEP = 4

export const KEY_CONTRACT_ID = "contractId"
export const KEY_BENEFICIARY_ID = "beneficiaryId"
export const KEY_INSURANCE_ID = "insuranceId"
export const KEY_BUYER_ID = "ownerId"

export const KEY_DATA_FEES = "dataFees"
export const KEY_TOTAL_FEE = "totalFee"
export const KEY_CONTRACT_INFO = "contractInfo"
export const KEY_CONTRACT_CODE = "contractCode"

export const KEY_COMPANY_ID = "companyId"
export const KEY_ACTIVE_STEP = "activeStep"
export const KEY_PAYMENT_TYPE = "paymentType"
export const KEY_HAS_CAL_FEE_DONE = "hasCalFeeDone"
export const KEY_PAY_CONTRACT_STATUS = "payContractStatus"
export const KEY_AGREED_TERM_OF_SERVICES_STATUS = "agreedTermOfServicesStatus"

export const KEY_STEP_1 = "step_1"
export const KEY_STEP_2 = "step_2"

const initialState = {
    [KEY_CONTRACT_ID]: ``,
    [KEY_BENEFICIARY_ID]: ``,
    [KEY_INSURANCE_ID]: ``,
    [KEY_BUYER_ID]: ``,

    [KEY_DATA_FEES]: [],
    [KEY_TOTAL_FEE]: 0,
    [KEY_CONTRACT_INFO]: {},
    [KEY_CONTRACT_CODE]: "",
    [KEY_COMPANY_ID]: COMPANIES[0].id,
    [KEY_ACTIVE_STEP]: 1,
    [KEY_PAYMENT_TYPE]: PAYMENT_TYPE_CASH,
    [KEY_HAS_CAL_FEE_DONE]: false,
    [KEY_PAY_CONTRACT_STATUS]: PAID_WAITING,
    [KEY_AGREED_TERM_OF_SERVICES_STATUS]: false,

    [KEY_STEP_1]: {},
    [KEY_STEP_2]: {},
}

const buyInsurancesReducer = (state = initialState, action) => {
    const { payload, type } = action
    let _draftState = { ...state }

    switch (type) {
        case ACTION_MERGE_PROPS:
            for (let item of payload) {
                const { prop, value } = item
                if (prop === KEY_STEP_1) {
                    const buyerInfo = {
                        [KEY_BUYER_IC_TYPE]: value.icType || _draftState[prop][KEY_BUYER_IC_TYPE],
                        [KEY_BUYER_IC_NO]: value.icNo || _draftState[prop][KEY_BUYER_IC_NO],
                        [KEY_BUYER_FULLNAME]: value.fullName || _draftState[prop][KEY_BUYER_FULLNAME],
                        [KEY_BUYER_PHONE_NUMBER]: value.phoneNumber || _draftState[prop][KEY_BUYER_PHONE_NUMBER],
                        [KEY_BUYER_EMAIL]: value.email || _draftState[prop][KEY_BUYER_EMAIL],
                        [KEY_BUYER_GENDER]: value.gender || _draftState[prop][KEY_BUYER_GENDER],
                        [KEY_BUYER_DATE_BIRTH]: value.dateOfBirth || _draftState[prop][KEY_BUYER_DATE_BIRTH],
                        [KEY_BUYER_CITY]: value.city || _draftState[prop][KEY_BUYER_CITY],
                        [KEY_BUYER_DISTRICT]: value.district || _draftState[prop][KEY_BUYER_DISTRICT],
                        [KEY_BUYER_WARD]: value.ward || _draftState[prop][KEY_BUYER_WARD],
                        [KEY_BUYER_ADDRESS]: value.address || _draftState[prop][KEY_BUYER_ADDRESS]
                    }
                    _draftState[prop] = {
                        ..._draftState[prop],
                        ...buyerInfo
                    }
                } else {
                    _draftState[prop] = value
                }
            }
            return _draftState

        case ACTION_UPDATE_PROPS:
            for (let item of payload) {
                const { prop, value } = item
                _draftState[prop] = value
            }
            return _draftState

        case ACTION_RESET_ALL:
            return initialState

        default:
            return state
    }
}

export default buyInsurancesReducer
