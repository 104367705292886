import { BaseAppUltils, HttpClient } from 'base-app'

import {
  API_TINBAOAN_GET_DETAILS,
  API_TINBAOAN_DELETE_CONTRACT,
  API_TINBAOAN_CREATE_CONTRACT_PARTNER,
  API_UPDATE_PAYMENT_INFO,
  API_GET_BANK_TRANSFER_INFO, PERSONAL_INSURANCE_URL
} from '../../configs/insurance-app';
import {API_CONTRACTS_TD_CONFIRM_PAYCONTACT} from "../../pages/insurance-app/buy-insurances/tinbaoan-insurance/components/step1/utility";



class Service {
  static createContract(contractInfo) {

    const headers = {
      clientMessageId: BaseAppUltils.generateUUID()
    }

    return HttpClient.post(`${PERSONAL_INSURANCE_URL.TD_PATH.createContract}`, contractInfo,{
      headers:headers
    });
  }

  static updateContract(contractInfo, contractId) {
    const headers = {
      clientMessageId: BaseAppUltils.generateUUID(),
      appId: 'INSURANCE_APP'
    }

    const url = `${PERSONAL_INSURANCE_URL.TD_PATH.updateContract}/${contractId}`

    return HttpClient.put(url, contractInfo, {
      headers: headers
    })
  }

  static getContractDetails(contractId) {

    const headers = {
      clientMessageId: BaseAppUltils.generateUUID()
    }

    return HttpClient.get(`${PERSONAL_INSURANCE_URL.TD_PATH.findOne}?contractId=${contractId}`,{
      headers:headers
    });
  }

  static getProposalForm(contractCode){
    const headers = {
      clientMessageId: BaseAppUltils.generateUUID()
    }

    return HttpClient.get(`${PERSONAL_INSURANCE_URL.TD_PATH.getProposalForm}?contractCode=${contractCode}`, {
      headers: headers,
    });
  }

  static confirmSmsOtp(otp, contractCode) {
    const headers = {
      clientMessageId: BaseAppUltils.generateUUID(),
    }

    return HttpClient.get(`${PERSONAL_INSURANCE_URL.TD_PATH.confirmSmsOtp}`, {
      headers: headers,
      params: {
        otp,
        contractCode
      }
    })
  }

  static confirmSmsOtpPublic(otp,contractCode) {
    const headers = {
      clientMessageId: BaseAppUltils.generateUUID(),
    }

    return HttpClient.get(`${PERSONAL_INSURANCE_URL.TD_PATH.confirmSmsOtpPublic}`, {
      headers: headers,
      params: {
        otp,
        contractCode
      }
    })
  }

  static deleteContract(contractId) {

    const headers = {
      clientMessageId: BaseAppUltils.generateUUID()
    }

    return HttpClient.delete(`${PERSONAL_INSURANCE_URL.TD_PATH.deleteContract}?contractId=${contractId}`,{
      headers:headers
    });
  }

  static createContractPartner(contractId) {
    const headers = {
      clientMessageId: BaseAppUltils.generateUUID()
    }

    return HttpClient.get(`${PERSONAL_INSURANCE_URL.TD_PATH.createContractPartner}?contractId=${contractId}`,{
      headers:headers
    });
  }
 
  static getBank() {
    return HttpClient.get(`${API_GET_BANK_TRANSFER_INFO}`, {
      headers: {
        appId: 'INSURANCE_APP'
      }
    });
  }

  static updatePaymentInfo(paymentInfo) {
    const headers = {
      clientMessageId: BaseAppUltils.generateUUID()
    };
    return HttpClient.put(`${PERSONAL_INSURANCE_URL.TD_PATH.updatePaymentType}`, paymentInfo, { headers });
  }

  static payContract(contractId, paymentType) {
    const headers = {
      clientMessageId: BaseAppUltils.generateUUID()
    };
    const data = {
      contractId,
      paymentType
    }

    return HttpClient.put(`${PERSONAL_INSURANCE_URL.TD_PATH.payContract}`, data, {
      headers
    });
  }
}

export default Service;