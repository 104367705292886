import React from "react"
import { Card, CardBody, FormGroup, Row, Col } from "reactstrap"
import successImg from "../../../assets/images/insurance-app/buy-insurance/frame_waiting.png"
import {
  FormattedMessage,
  AppId
} from 'base-app'
import {useHistory} from 'react-router-dom'
import { NAME_BUY_INSURANCES_BVAG } from "../../../configs/insurance-app"
const WaitingPaymentComponent = ({ reduxName , allAnswerIsNo = true , certNumber}) => {
  const history = useHistory()
      const renderTextSmall = () => {
      return (
        <div>
          Mã đơn hàng của đối tác là <span className="text-primary text-uppercase font-weight-bold cursor-pointer">{certNumber}</span> và kết quả đánh giá rủi ro sẽ được gửi tới SMS/Email của Khách hàng. Sau đó vui lòng truy cập <span className="text-primary text-uppercase font-weight-bold cursor-pointer" onClick={() => history.push('/insurance/contracts/personal-buyInsurancBvag')}>Quản lý hợp đồng</span> để nhập mã xác nhận OTP hoặc hướng dẫn khách hàng xác nhận từ link trong SMS/Email.
         </div>
      )
    }
    return (
      <Row className="m-0 w-100">
      <Col sm="12">
        <Card className="auth-card bg-transparent shadow-none rounded-0 mb-0 w-100">
          <CardBody className="text-center">
            <div className="bg-full-screen-image">
              <img
                src={successImg}
                alt="successImg"
                className="img-fluid align-self-center mt-75"
              />

              
              <FormGroup className="form-label-group margin-top-14 margin-bottom-14">
                <h1 className="font-large-1 primary letter-uppercase"><FormattedMessage id={`${reduxName === NAME_BUY_INSURANCES_BVAG && allAnswerIsNo ? `${AppId.INSURANCE_APP}.bvag.payment.waitingAccept` :  reduxName === NAME_BUY_INSURANCES_BVAG && !allAnswerIsNo ? `${AppId.INSURANCE_APP}.bvag.payment.waitingPayment` :`${AppId.INSURANCE_APP}.PaymentWaiting`}`} /></h1>
                <p>
                  {
                    reduxName === NAME_BUY_INSURANCES_BVAG && !allAnswerIsNo && renderTextSmall() 
                  }
                  {
                    reduxName !== NAME_BUY_INSURANCES_BVAG &&  <FormattedMessage id={`${AppId.INSURANCE_APP}.PaymentSuccessContent`} /> 
                  }

            </p>
              </FormGroup>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
    )
}
export default WaitingPaymentComponent
