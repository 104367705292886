import React from 'react'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'
import * as Yup from 'yup'
import { getKeyLang } from '../../../../../../configs/insurance-app'
import Utils from '../../../../../../configs/insurance-app/constants/Utils'
import * as config from '../../../../../../components/insurance-app/buy-insurances-page/formik-config'
import { sleepingFor } from '../../../../../../ultity'
import { KEY_TAX_ID } from '../../../car/components/step6/formik-config/copr'

export const BuyerTypes = [
    {
    label: <FormattedMessage id={getKeyLang(`tba.buyerType.individual`)} />,
    value: "INDIVIDUAL",
    content: config.AC_BUYER_TYPE_INV
    },
    {
    label: <FormattedMessage id={getKeyLang(`tba.buyerType.corporation`)} />,
    value: "CORPORATE",
    content: config.AC_BUYER_TYPE_CORP
    }
]

export const insuredPersonTypes = [
    {
        label: <FormattedMessage id={getKeyLang(`houseOwner`)} />,
        content: "OWNER",
        value: 1,
    },
    {
        label: <FormattedMessage id={getKeyLang(`hiredPerson`)} />,
        content: "OTHER",
        value: 2,
    },
]

export const IDTypes = [
    {
        label: <FormattedMessage id={getKeyLang(`IDCMND`)} />,
        content: config.ID_TYPE_CMND,
        value: 1,
    },
    {
        label: <FormattedMessage id={getKeyLang(`IDCCCD`)} />,
        content: config.ID_TYPE_CCCD,
        value: 2,
    }
]

export const sugg_Sex = [
    {
        value: "1",
        content: "MALE",
        label: <FormattedMessage id={getKeyLang(`Male`)} />
    },
    {
        value: "2",
        content: "FEMALE",
        label: <FormattedMessage id={getKeyLang(`Female`)} />,
    },
    {
        value: "3",
        content: "OTHER",
        label: <FormattedMessage id={getKeyLang(`Other`)} />,
    }
]

export const houseTypes = [
    {
        label: <FormattedMessage id={getKeyLang(`AC.apartment`)} />,
        content: "APARTMENT",
        value: 1,
    },
    {
        label: <FormattedMessage id={getKeyLang(`AC.privateHouse`)} />,
        content: "HOUSE",
        value: 2,
    }
]

export const houseLevels = [
    { value: 1, label: 'LEVEL 01' },
    { value: 2, label: 'LEVEL 02' },
    { value: 3, label: 'LEVEL 03' }
]

export const KEY_INSURED_TYPE = "houseOwnerType"

export const KEY_IC_TYPE = "icType"
export const KEY_ISSUED_DATE = "issuedDate"
export const KEY_ISSUED_PLACE = "issuedPlace"

export const KEY_IC_NO = "icNo"
export const KEY_FULL_NAME = "fullName"
export const KEY_DATE_BIRTH = "dateOfBirth"
export const KEY_PHONE_NUMBER = "phoneNumber"
export const KEY_EMAIL = "email"
export const KEY_GENDER = "gender"

export const KEY_CITY = config.KEY_CITY
export const KEY_DISTRICT = config.KEY_DISTRICT
export const KEY_WARD = config.KEY_WARD
export const KEY_ADDRESS = config.KEY_ADDRESS

export const KEY_TOGGLE_IS_ADDRESS_EQUAL = "theSameAddress"
export const KEY_ADDTIONAL_ADDRESSES = "houseAddressDTO"

export const KEY_HOUSE_TYPE = "houseType"
export const MAX_USED_TIME = 24
export const KEY_TIME_USED = "usedTime"

// Buyer info
export const KEY_BUYER = "buyer"

export const KEY_BUYER_TYPE = "buyerType"
export const KEY_BUYER_IC_TYPE = "buyerIcType"
export const KEY_BUYER_ISSUED_DATE = "buyerIssuedDate"
export const KEY_BUYER_ISSUED_PLACE = "buyerIssuedPlace"
export const KEY_BUYER_IC_NO = "buyerIcNo"
export const KEY_BUYER_FULLNAME = "buyerFullname"
export const KEY_BUYER_DATE_BIRTH = "buyerDateOfBirth"
export const KEY_BUYER_GENDER = "buyerGender"
export const KEY_BUYER_PHONE_NUMBER = "buyerPhoneNumber"
export const KEY_BUYER_EMAIL = "buyerEmail"

// Buyer address
export const KEY_BUYER_CITY = "buyerCity"
export const KEY_BUYER_DISTRICT = "buyerDistrict"
export const KEY_BUYER_WARD = "buyerWard"
export const KEY_BUYER_ADDRESS = "buyerAddress"

// Corporate buyer
export const KEY_BUYER_ADDRESS_TAX = "buyerAddressTax"
export const KEY_BUYER_CITY_TAX = "buyerCityTax"
export const KEY_BUYER_DISTRICT_TAX = "buyerDistrictTax"
export const KEY_BUYER_WARD_TAX = "buyerWardTax"

export const KEY_BUYER_FAX_CODE = "faxCode"
export const KEY_BUYER_SELF_BENEFICIARY = "selfBeneficiary"

//AC Home
export const KEY_HOUSE_LEVEL = "houseLevel"
export const KEY_LAND_CERTIFICATE = "landCertificate"
export const KEY_BUYER_CIF = "cif"

export const KEY_APARTMENT_NAME = "apartmentName"


export const addtionalAddressDefault = {
    [KEY_CITY]: "",
    [KEY_DISTRICT]: "",
    [KEY_WARD]: "",
    [KEY_ADDRESS]: "",
}

export const initialValues = ({
    [KEY_INSURED_TYPE]: insuredPersonTypes[0].content,

    [KEY_IC_TYPE]: IDTypes[0].content,
    [KEY_ISSUED_DATE]: "",
    [KEY_ISSUED_PLACE]: "",

    [KEY_IC_NO]: "",
    [KEY_FULL_NAME]: "",
    [KEY_DATE_BIRTH]: "",
    [KEY_PHONE_NUMBER]: "",
    [KEY_EMAIL]: "",
    [KEY_GENDER]: sugg_Sex[0].content,

    [KEY_CITY]: "",
    [KEY_DISTRICT]: "",
    [KEY_WARD]: "",
    [KEY_ADDRESS]: "",

    [KEY_TOGGLE_IS_ADDRESS_EQUAL]: true,
    [KEY_ADDTIONAL_ADDRESSES]: [],

    [KEY_HOUSE_TYPE]: houseTypes[1].content,

    [KEY_BUYER_TYPE]: BuyerTypes[0].content,
    [KEY_BUYER_IC_TYPE]: IDTypes[0].content,
    [KEY_BUYER_ISSUED_DATE]: "",
    [KEY_BUYER_ISSUED_PLACE]: "",
    [KEY_BUYER_IC_NO]: "",

    [KEY_BUYER_FULLNAME]: "",
    [KEY_BUYER_DATE_BIRTH]: "",
    [KEY_BUYER_GENDER]: sugg_Sex[0].content,
    [KEY_BUYER_PHONE_NUMBER]: "",
    [KEY_BUYER_EMAIL]: "",

    [KEY_BUYER_FAX_CODE]: "",

    [KEY_BUYER_CITY]: "",
    [KEY_BUYER_DISTRICT]: "",
    [KEY_BUYER_WARD]: "",
    [KEY_BUYER_ADDRESS]: "",

    [KEY_BUYER_ADDRESS_TAX]: "",
    [KEY_BUYER_CITY_TAX]: "",
    [KEY_BUYER_DISTRICT_TAX]: "",
    [KEY_BUYER_WARD_TAX]: "",

    [KEY_BUYER_SELF_BENEFICIARY]: true,
    [KEY_BUYER_CIF]: "",
    [KEY_HOUSE_LEVEL]: houseLevels[0].value,
    [KEY_LAND_CERTIFICATE]: "",
    [KEY_APARTMENT_NAME]: "",
})

export const validate = (values) => {
    return sleepingFor().then(() => {
        let errors = {}
        const { [KEY_CITY]: city, [KEY_ADDRESS]: address, [KEY_BUYER]: buyers } = values

        if (!values[KEY_TOGGLE_IS_ADDRESS_EQUAL]) {
            if (!city) {
                if (!address) {
                    errors[KEY_ADDRESS] = config.ALERT_INVALID
                }
                return errors
            }
            if (city.trim().match(config.nullStrRegex) && address.trim().match(config.nullStrRegex)) {
                errors[KEY_ADDRESS] = config.ALERT_INVALID
            }
            return errors
        }
        if (!city || city.trim().match(config.nullStrRegex)) {
            errors[KEY_CITY] = config.ALERT_INVALID
        }
        if (!address || address.trim().match(config.nullStrRegex)) {
            errors[KEY_ADDRESS] = config.ALERT_INVALID
        }
        // Buyer type validation
        if (!values[KEY_BUYER_TYPE]) {
            errors[KEY_BUYER_TYPE] = 'Vui lòng chọn loại người mua'
        }

        if (values[KEY_BUYER_TYPE] === "CORPORATE") {
            // Validate for CORPORATE customers
            if (!values[KEY_BUYER_IC_NO]) {
                errors[KEY_BUYER_IC_NO] = 'Vui lòng nhập mã số thuế'
            } else if (!/^\d{10}$/.test(values[KEY_BUYER_IC_NO])) {
                errors[KEY_BUYER_IC_NO] = 'Mã số thuế phải là 10 chữ số'
            }
            if (!values[KEY_BUYER_ADDRESS_TAX]) {
                errors[KEY_BUYER_ADDRESS_TAX] = 'Vui lòng nhập địa chỉ thuế'
            } else if (values[KEY_BUYER_ADDRESS_TAX].length < 5) {
                errors[KEY_BUYER_ADDRESS_TAX] = 'Địa chỉ thuế phải có ít nhất 5 ký tự'
            } else if (values[KEY_BUYER_ADDRESS_TAX].length > 200) {
                errors[KEY_BUYER_ADDRESS_TAX] = 'Địa chỉ thuế không được vượt quá 200 ký tự'
            }
            if (!values[KEY_BUYER_CITY_TAX]) errors[KEY_BUYER_CITY_TAX] = 'Vui lòng chọn thành phố'
            if (!values[KEY_BUYER_DISTRICT_TAX]) errors[KEY_BUYER_DISTRICT_TAX] = 'Vui lòng chọn quận/huyện'
            if (!values[KEY_BUYER_WARD_TAX]) errors[KEY_BUYER_WARD_TAX] = 'Vui lòng chọn phường/xã'
            
            if (!values[KEY_BUYER_FULLNAME]) {
                errors[KEY_BUYER_FULLNAME] = 'Vui lòng nhập tên doanh nghiệp'
            } else if (values[KEY_BUYER_FULLNAME].length < 5) {
                errors[KEY_BUYER_FULLNAME] = 'Tên doanh nghiệp phải có ít nhất 5 ký tự'
            } else if (values[KEY_BUYER_FULLNAME].length > 200) {
                errors[KEY_BUYER_FULLNAME] = 'Tên doanh nghiệp không được vượt quá 200 ký tự'
            }

            if (!values[KEY_BUYER_CITY]) errors[KEY_BUYER_CITY] = 'Vui lòng chọn thành phố'
            if (!values[KEY_BUYER_DISTRICT]) errors[KEY_BUYER_DISTRICT] = 'Vui lòng chọn quận/huyện'
            if (!values[KEY_BUYER_WARD]) errors[KEY_BUYER_WARD] = 'Vui lòng chọn phường/xã'

            if (!values[KEY_BUYER_ADDRESS]) {
                errors[KEY_BUYER_ADDRESS] = 'Vui lòng nhập địa chỉ giao dịch'
            } else if (values[KEY_BUYER_ADDRESS].length < 5) {
                errors[KEY_BUYER_ADDRESS] = 'Địa chỉ giao dịch phải có ít nhất 5 ký tự'
            } else if (values[KEY_BUYER_ADDRESS].length > 200) {
                errors[KEY_BUYER_ADDRESS] = 'Địa chỉ giao dịch không được vượt quá 200 ký tự'
            }
        } else {
            // Validate for individual customers
            if (!values[KEY_BUYER_FULLNAME]) {
                errors[KEY_BUYER_FULLNAME] = 'Vui lòng nhập họ tên'
            } else if (values[KEY_BUYER_FULLNAME].length < 5) {
                errors[KEY_BUYER_FULLNAME] = 'Họ tên phải có ít nhất 5 ký tự'
            } else if (values[KEY_BUYER_FULLNAME].length > 50) {
                errors[KEY_BUYER_FULLNAME] = 'Họ tên không được vượt quá 50 ký tự'
            }
            
            if (!values[KEY_BUYER_DATE_BIRTH]) {
                errors[KEY_BUYER_DATE_BIRTH] = 'Vui lòng chọn ngày sinh'
            } else {
                const birthDate = moment(values[KEY_BUYER_DATE_BIRTH]);
                if (!birthDate.isValid()) {
                    errors[KEY_BUYER_DATE_BIRTH] = 'Ngày sinh không hợp lệ';
                } else {
                    const age = moment().diff(birthDate, 'years', true);
                    if (age < 18) {
                        errors[KEY_BUYER_DATE_BIRTH] = 'Người mua bảo hiểm phải đủ 18 tuổi'
                    } else if (age > 72) {
                        errors[KEY_BUYER_DATE_BIRTH] = 'Người mua bảo hiểm không được quá 72 tuổi'
                    }
                }
            }
            
            if (!values[KEY_BUYER_IC_NO]) {
                errors[KEY_BUYER_IC_NO] = 'Vui lòng nhập số giấy tờ tùy thân'
            } else if (values[KEY_BUYER_IC_NO].length < 8) {
                errors[KEY_BUYER_IC_NO] = 'Số giấy tờ tùy thân phải có ít nhất 8 ký tự'
            } else if (values[KEY_BUYER_IC_NO].length > 12) {
                errors[KEY_BUYER_IC_NO] = 'Số giấy tờ tùy thân không được vượt quá 12 ký tự'
            }
            
            if (!values[KEY_BUYER_GENDER]) {
                errors[KEY_BUYER_GENDER] = 'Vui lòng chọn giới tính'
            }

            if (!values[KEY_BUYER_CITY]) errors[KEY_BUYER_CITY] = 'Vui lòng chọn thành phố'
            if (!values[KEY_BUYER_DISTRICT]) errors[KEY_BUYER_DISTRICT] = 'Vui lòng chọn quận/huyện'
            if (!values[KEY_BUYER_WARD]) errors[KEY_BUYER_WARD] = 'Vui lòng chọn phường/xã'

            if (!values[KEY_BUYER_ADDRESS]) {
                errors[KEY_BUYER_ADDRESS] = 'Vui lòng nhập địa chỉ'
            } else if (values[KEY_BUYER_ADDRESS].length < 3) {
                errors[KEY_BUYER_ADDRESS] = 'Địa chỉ phải có ít nhất 3 ký tự'
            } else if (values[KEY_BUYER_ADDRESS].length > 200) {
                errors[KEY_BUYER_ADDRESS] = 'Địa chỉ không được vượt quá 200 ký tự'
            }
        }
        
        // Common validations
        if (!values[KEY_BUYER_PHONE_NUMBER]) {
            errors[KEY_BUYER_PHONE_NUMBER] = 'Vui lòng nhập số điện thoại'
        } else if (!/^(?:(?:\+84|0)[35789]\d{8}|(1900|1800|1901|1902)\d{4}$|(02)\d{9}$)/i.test(values[KEY_BUYER_PHONE_NUMBER])) {
            errors[KEY_BUYER_PHONE_NUMBER] = 'Số điện thoại không hợp lệ'
        }
        if (!values[KEY_BUYER_EMAIL]) {
            errors[KEY_BUYER_EMAIL] = 'Vui lòng nhập email'
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values[KEY_BUYER_EMAIL]) || values[KEY_BUYER_EMAIL].length < 3 || values[KEY_BUYER_EMAIL].length > 50) {
            errors[KEY_BUYER_EMAIL] = 'Email không hợp lệ hoặc độ dài không đúng (3-50 ký tự)'
        }
        
        if (!values[KEY_BUYER_PHONE_NUMBER]) errors[KEY_BUYER_PHONE_NUMBER] = 'Vui lòng nhập số điện thoại'

        if (!values[KEY_LAND_CERTIFICATE]) {
            errors[KEY_LAND_CERTIFICATE] = 'Vui lòng nhập số GCNQSDĐ/ QSDNO'
        }

        if (values[KEY_HOUSE_TYPE] === "APARTMENT") {
            if (!values[KEY_APARTMENT_NAME]) {
                errors[KEY_APARTMENT_NAME] = 'Vui lòng nhập tên chung cư'
            } else if (values[KEY_APARTMENT_NAME].length < 2) {
                errors[KEY_APARTMENT_NAME] = 'Tên chung cư phải có ít nhất 2 ký tự'
            } else if (values[KEY_APARTMENT_NAME].length > 200) {
                errors[KEY_APARTMENT_NAME] = 'Tên chung cư không được vượt quá 200 ký tự'
            }
        }

        return errors
    })
}

export const validationSchema = Yup.object().shape({
  [KEY_FULL_NAME]: Yup.string().when(KEY_BUYER_TYPE, {
    is: 'CORPORATE',
    then: Yup.string()
      .required('Vui lòng nhập họ tên')
      .min(5, 'Họ tên phải có ít nhất 5 ký tự')
      .max(50, 'Họ tên không được vượt quá 50 ký tự'),
  }),

  [KEY_DATE_BIRTH]: Yup.date().when(KEY_BUYER_TYPE, {
    is: 'CORPORATE', 
    then: Yup.date()
      .required('Vui lòng chọn ngày sinh')
      .test('age', 'Người thụ hưởng phải đủ 18 tuổi', function (value) {
        const age = moment().diff(moment(value), 'years', true);
        return age >= 18;
      })
      .test('maxAge', 'Người thụ hưởng không được quá 72 tuổi', function (value) {
        const age = moment().diff(moment(value), 'years', true);
        return age <= 72;
      }),
  }),

  [KEY_IC_NO]: Yup.string().when(KEY_BUYER_TYPE, {
    is: 'CORPORATE',
    then: Yup.string()
      .required('Vui lòng nhập số CMND')
      .min(8, 'Số CMND phải có ít nhất 8 ký tự')
      .max(12, 'Số CMND không được vượt quá 12 ký tự'),
  }),

  [KEY_GENDER]: Yup.string().when(KEY_BUYER_TYPE, {
    is: 'CORPORATE',
    then: Yup.string()
      .required('Vui lòng chọn giới tính'),
  }),

  [KEY_CITY]: Yup.string().when(KEY_BUYER_TYPE, {
    is: 'CORPORATE',
    then: Yup.string()
      .required('Vui lòng chọn thành phố'),
  }),

  [KEY_DISTRICT]: Yup.string().when(KEY_BUYER_TYPE, {
    is: 'CORPORATE',
    then: Yup.string()
      .required('Vui lòng chọn quận/huyện'),
  }),

  [KEY_WARD]: Yup.string().when(KEY_BUYER_TYPE, {
    is: 'CORPORATE',
    then: Yup.string()
      .required('Vui lòng chọn phường/xã'),
  }),

  [KEY_ADDRESS]: Yup.string().when(KEY_BUYER_TYPE, {
    is: 'CORPORATE',
    then: Yup.string()
      .required('Vui lòng nhập địa chỉ')
      .min(3, 'Địa chỉ phải có ít nhất 3 ký tự')
      .max(200, 'Địa chỉ không được vượt quá 200 ký tự'),
  }),

  [KEY_PHONE_NUMBER]: Yup.string().when(KEY_BUYER_TYPE, {
    is: 'CORPORATE',
    then: Yup.string()
      .required('Vui lòng nhập số điện thoại')
      .matches(
        /^(?:(?:\+84|0)[35789]\d{8}|(1900|1800|1901|1902)\d{4}$|(02)\d{9}$)/i,
        'Số điện thoại không hợp lệ'
      ),
  }),

  [KEY_EMAIL]: Yup.string().when(KEY_BUYER_TYPE, {
    is: 'CORPORATE',
    then: Yup.string()
      .required('Vui lòng nhập email')
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        'Email không hợp lệ'
      )
      .min(3, 'Email phải có ít nhất 3 ký tự')
      .max(50, 'Email không được vượt quá 50 ký tự'),
  }),

  [KEY_APARTMENT_NAME]: Yup.string().when(KEY_HOUSE_TYPE, {
    is: "APARTMENT",
    then: Yup.string()
        .required('Vui lòng nhập tên chung cư')
        .min(3, 'Tên chung cư phải có ít nhất 3 ký tự')
        .max(100, 'Tên chung cư không được vượt quá 100 ký tự'),
    otherwise: Yup.string()
  }),
})

