import { getDefault_buyInsuranceReducer, getDefault_initialState } from "./utility"
import { PATH_BUY_INSURANCES_BVAG } from "../../../configs/insurance-app"
import * as _ from './utility'
import moment from "moment"
import { ELECTROMAGNETIC_CARD, GOLD, Insurance_addOns } from "../../../pages/insurance-app/buy-insurances/bao-viet-an-gia/components/step2/utility"
import { DIAMOND } from "../../../pages/insurance-app/buy-insurances/happy-life/components/step2/utility"
import { PAYMENT_TYPE_ATM } from "../../../components/insurance-app/buy-insurances-page/formik-config"

export const ACTION_BUY_INSUR_BVAG_UPDATE_PROPS = 'ACTION_BUY_INSUR_BVAG_UPDATE_PROPS'
export const ACTION_BUY_INSUR_BVAG_RESET_ALL = 'ACTION_BUY_INSUR_BVAG_RESET_ALL'

export const BASE = _
export const MAX_STEP = 4

export const KEY_INSURANCE_INFO = "insuranceInfo"
export const KEY_STEP_2 = "step_2"
export const KEY_ADDONS = "addOns"
export const KEY_ANSWERED_STATUS = 'answered'
export const KEY_SURVEY_ID = 'surveyId'
export const KEY_ALL_ANSER_IS_NO = 'allAnswerIsNo'
export const KEY_CERTNUMBER = 'certNumber'
export const KEY_DATA_FEE_FOR_PERSON = 'dataFeeForPerson'
export const KEY_PERSON_SELECT = 'personSelect'
export const KEY_PARENT_ICNO = 'parentIcNo'
export const KEY_IS_BUY_AGAIN_CONTRACT = 'buyAgainContract'
export const KEY_PASSED_OTP = 'passedOtp'
export const initialState = getDefault_initialState()
const date = new Date()
const hours = date.getHours()
initialState[KEY_STEP_2] = {}
initialState[KEY_ADDONS] = Insurance_addOns
initialState[KEY_INSURANCE_INFO] = []
initialState[_.KEY_LAST_ENDPOINT_PATH] = PATH_BUY_INSURANCES_BVAG
initialState[_.KEY_COMPANY_ID] = "07"
initialState[KEY_ANSWERED_STATUS] = true
initialState[KEY_SURVEY_ID] = ''
initialState[KEY_ALL_ANSER_IS_NO] = true
initialState[KEY_CERTNUMBER] = ''
initialState[_.KEY_PAYMENT_TYPE] = PAYMENT_TYPE_ATM
initialState[KEY_DATA_FEE_FOR_PERSON] = []
initialState[KEY_PERSON_SELECT] = null
initialState[KEY_PARENT_ICNO] = ''
initialState[KEY_IS_BUY_AGAIN_CONTRACT] = false
initialState[KEY_PASSED_OTP] = false


const reducer = getDefault_buyInsuranceReducer(initialState, ACTION_BUY_INSUR_BVAG_UPDATE_PROPS, ACTION_BUY_INSUR_BVAG_RESET_ALL)

export default reducer