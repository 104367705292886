import { UPDATE_COMPANY_ID, UPDATE_CONTRACT_CODE, UPDATE_CONTRACT_INFO, UPDATE_DATA_FEES, UPDATE_EDIT_INFO_VHC_STATUS, UPDATE_LIST_CAR_TYPE, UPDATE_PROPS_RENEWAL } from "../../../actions/elite-app/renewal"

export const KEY_CONTRACT_ID = 'contractId'
export const KEY_OWNER = ' owner'
export const KEY_INSURANCES = 'insurances'
export const KEY_CONTRACT_CODE = 'contractCode'
export const KEY_STEP_DATA = 'stepData'
export const KEY_COMPANY_ID = 'companyId'
export const KEY_TOTAL_FEE = 'totalFee'
export const KEY_PAYMENT_STATUS = 'paymentStatus'
const initialState = {
    contractInfo: {},
    dataFees: [],
    companyId : "06",
    listCarType : [],
    // status editblock 
    editInfoVehicelStatus : false ,
    [KEY_CONTRACT_CODE] : "",
    [KEY_CONTRACT_ID] : "",
    [KEY_OWNER] : {},
    [KEY_INSURANCES] : [],
    [KEY_STEP_DATA] : {},
    [KEY_COMPANY_ID] : "01",
    [KEY_TOTAL_FEE] : 0,
    [KEY_PAYMENT_STATUS] : ''
}

const renewalInsuranceReducers = (state = initialState, action) => {
    const { payload, type } = action
    let _draftState = { ...state }

    switch (type) {
        case UPDATE_PROPS_RENEWAL:
            for (let item of payload) {
                const { prop, value } = item
                _draftState[prop] = value
            }
            return _draftState
        case UPDATE_CONTRACT_INFO:
            return { ...state, contractInfo: payload }
        case UPDATE_DATA_FEES:
            return { ...state, dataFees: payload }
        case UPDATE_COMPANY_ID:
            return { ...state, companyId: payload }
        case UPDATE_LIST_CAR_TYPE:
            return { ...state, listCarType: payload }
        case UPDATE_EDIT_INFO_VHC_STATUS:
            return { ...state, editInfoVehicelStatus: payload }
        case UPDATE_CONTRACT_CODE:
            return { ...state, contractCode: payload }
        default:
            return state
    }
}

export default renewalInsuranceReducers