import { getDefault_buyInsuranceReducer, getDefault_initialState } from "./utility"
import { PATH_BUY_INSURANCES_HAPPY_LIFE } from "../../../configs/insurance-app"
import * as _ from './utility'
import { PAYMENT_TYPE_FUND_TRANSFER } from "../../../components/insurance-app/buy-insurances-page/formik-config"
import moment from "moment"
import { DIAMOND, Insurance_addOns } from "../../../pages/insurance-app/buy-insurances/happy-life/components/step2/utility"

export const ACTION_BUY_INSUR_HAPPY_LIFE_UPDATE_PROPS = 'ACTION_BUY_INSUR_HAPPY_LIFE_UPDATE_PROPS'
export const ACTION_BUY_INSUR_HAPPY_LIFE_RESET_ALL = 'ACTION_BUY_INSUR_HAPPY_LIFE_RESET_ALL'

export const BASE = _
export const MAX_STEP = 4

export const KEY_INSURANCE_INFO = "insuranceInfo"
export const KEY_STEP_2 = "step_2"
export const KEY_ADDONS = "addOns"
export const KEY_ANSWERED_STATUS = 'answered'
export const KEY_SURVEY_ID = 'surveyId'
export const initialState = getDefault_initialState()
initialState[KEY_STEP_2] = {
    productCode: DIAMOND ,
    startValueDate : moment(new Date).utc(true).format('YYYY-MM-DD'),
    endValueDate : moment(new Date).add(1, 'y').utc(true).format('YYYY-MM-DD'),
    duration : 12 , 
    year : 1 , 
    addOns : Insurance_addOns
}
initialState[KEY_ADDONS] = Insurance_addOns
initialState[KEY_INSURANCE_INFO] = []
initialState[_.KEY_LAST_ENDPOINT_PATH] = PATH_BUY_INSURANCES_HAPPY_LIFE
initialState[_.KEY_COMPANY_ID] = "01"
initialState[KEY_ANSWERED_STATUS] = true
initialState[KEY_SURVEY_ID] = ''
const reducer = getDefault_buyInsuranceReducer(initialState, ACTION_BUY_INSUR_HAPPY_LIFE_UPDATE_PROPS, ACTION_BUY_INSUR_HAPPY_LIFE_RESET_ALL)

export default reducer