import { BaseAppUltils, HttpClient } from "base-app"
import {
    API_CHECK_INFO_CONTACT_BY_ID,
    API_HOME_INSURANCE_ANCU,
    API_HOME_INSURANCE_CONTRACTS_VNPAY_CONFIRM,
    API_HOME_INSURANCE_FEE_INSURANCE,
    API_HOME_INSURANCE_INSURANCES
} from "../../configs/insurance-app"

class Service {
    static checkInfoContact(customerId) {
        return HttpClient.get(`${API_CHECK_INFO_CONTACT_BY_ID}/${customerId}`,
            { params: { date: new Date().getMilliseconds() } })
    }

    static confirmPay(values) {
        return HttpClient.post(`${API_HOME_INSURANCE_CONTRACTS_VNPAY_CONFIRM}`, values, { isBackgroundRequest: true })
    }


    static updateInsurance(contractId, insuranceAC) {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID()
        }
        return HttpClient.put(`${API_HOME_INSURANCE_ANCU.ANCU_INS_PATH.updateInsurance}/${contractId}`, insuranceAC,
            {
                headers: headers
            }
        )
    }

    static getContractInfo(contractId) {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID()
        }
        return HttpClient.get(`${API_HOME_INSURANCE_ANCU.ANCU_INS_PATH.getContractInfo}`,
            {
                params: {
                    contractId: contractId
                },
                headers: headers
            }
        )
    }

    static updatePaymentType(paymentType, contractId) {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID()
        }
        const companyInfo = {
            contractId: contractId,
            paymentType: paymentType
        }
        return HttpClient.post(`${API_HOME_INSURANCE_ANCU.ANCU_INS_PATH.updatePaymentType}`, companyInfo,
            {
                headers: headers
            }
        )
    }


    static getFeeInsurance(contractId) {
        return HttpClient.get(`${API_HOME_INSURANCE_FEE_INSURANCE}/${contractId}`,
            {
                params: { uuid: BaseAppUltils.generateUUID() },
                isBackgroundRequest: true
            })
    }

    static updateInsurances(info, contractId) {
        return HttpClient.put(`${API_HOME_INSURANCE_INSURANCES}/${contractId}`, info)
    }

    static updateContract(info, contractId) {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID(),
            appId: 'INSURANCE_APP'
        }
        return HttpClient.put(`${API_HOME_INSURANCE_ANCU.ANCU_INS_PATH.updateContract}/${contractId}`, info, {
            headers: headers
        })
    }

    static createContract(info) {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID(),
            appId: 'INSURANCE_APP'
        }
        return HttpClient.post(`${API_HOME_INSURANCE_ANCU.ANCU_INS_PATH.createContract}`, info, {
            headers: headers
        })
    }

    static payContract(contractId, paymentType) {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID()
        }
        return HttpClient.post(`${API_HOME_INSURANCE_ANCU.ANCU_INS_PATH.payContract}`, {
            contractId: contractId,
            paymentType: paymentType
        }, {
            headers: headers
        })
    }

    static getProposalForm(contractCode) {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID(),
            'Content-Type': 'application/json',
            'Accept': 'application/octet-stream'
        }

        return HttpClient.get(`${API_HOME_INSURANCE_ANCU.ANCU_INS_PATH.getProposalForm}`,{
            params: { contractCode: contractCode },
            headers: headers,
            responseType: 'blob'
        }).then(response => {
            const blob = new Blob([response.data], {
                type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;

            const fileName = `GYCBH_${contractCode}.docx`;
            link.setAttribute('download', fileName);

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            window.URL.revokeObjectURL(url);

        }).catch(error => {
            BaseAppUltils.toastError('Không thể tải file đề xuất. Vui lòng thử lại sau.')
        })
    }
}

export default Service
