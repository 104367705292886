import { FormattedMessage, HttpClient } from 'base-app'
import moment from 'moment'
import React from 'react'
import { PAYMENT_TYPE_FUND_TRANSFER } from '../../../../../../components/insurance-app/buy-insurances-page/formik-config'
import { NAME_BUY_INSURANCES_BVAG, getKeyLang } from '../../../../../../configs/insurance-app'
import { updateProps } from '../../../../../../redux/actions/insurance-app/buyInsurancesBvag'
import {
    BASE,
    KEY_INSURANCE_INFO,
    KEY_PERSON_SELECT,
    KEY_STEP_2
} from '../../../../../../redux/reducers/insurance-app/buyInsurancesBvag'
import {
    hasRequestFail,
    isArrayEmpty,
    isObjEmpty
} from '../../../../../../ultity'
import { RELATIONSHIP_SELF } from '../../../happy-life/components/step1/utility'
import {
    KEY_ADDRESS,
    KEY_ADDTIONAL_PEOPLE,
    KEY_CITY,
    KEY_DATE_BIRTH,
    KEY_DISTRICT,
    KEY_EMAIL,
    KEY_FILE_CONTENT,
    KEY_FILE_NAME,
    KEY_FILE_TYPE,
    KEY_FULLNAME,
    KEY_GENDER,
    KEY_IC_NO,
    KEY_IC_TYPE,
    KEY_INSURANCE_TYPE,
    KEY_IS_SELF,
    KEY_NATIONALITY,
    KEY_NUMBER_GCNBH_PARENTS,
    KEY_PHONE_NUMBER,
    KEY_RELATIONSHIP,
    KEY_WARD
} from './formikConfig'
import { ELECTROMAGNETIC_CARD, GOLD, Insurance_addOns, PLATINUM, checkAgeMin15ToMax365ForType, checkAgeMinToMaxForType, sortByDateOfBirthAscending } from '../step2/utility'
import { KEY_BEN_ID } from '../step2/formikConfig'
export const API_CONTRACTS = '/nth/personalinsurance/api/contracts/ba-contract'
export const API_UPDATE_SURVEYS = '/nth/personalinsurance/api/bv-an-gia/surveys'
export const createContract = (data, surveyId , values) => {
  return async (dispatch) => {
    try {
      const res = await HttpClient.post(`${API_CONTRACTS}`, data)
      if (res.status !== 200) return
      const res2 = await HttpClient.put(
        `${API_UPDATE_SURVEYS}/${surveyId}?contract_id=${res.data.id}`,
        data
      )
      if (res2.status !== 200) return
      const insurances = res.data.insurances[0]
      const date = new Date()
      const hours = date.getHours()
      const plusDate = hours >= 15 ? 2 : 1 
      const newDataStep2 = res.data.beneficiaries.map((_ben , index) => {
        return {
          idBeneficiari  : _ben.id,
          fullName : _ben.fullName,
          dateOfBirth : _ben.dateOfBirth,
          productCode: checkAgeMin15ToMax365ForType(res.data.beneficiaries) ? PLATINUM : check6age(res.data.beneficiaries) ? PLATINUM : GOLD,
          startValueDate : moment(new Date).add(plusDate , 'd').utc(true).format('YYYY-MM-DD'),
          endValueDate : moment(new Date).add(plusDate , 'd').add(1, 'y').utc(true).format('YYYY-MM-DD'),
          duration : 12, 
          year : 1 , 
          addOns : Insurance_addOns,
          lifeValue : 50000000,
          accidentValue : 50000000,
          formatCard : ELECTROMAGNETIC_CARD,
          gender : _ben.gender,
          relationship : values[KEY_ADDTIONAL_PEOPLE][index][KEY_RELATIONSHIP],
          [KEY_IC_NO] :  values[KEY_ADDTIONAL_PEOPLE][index][KEY_IC_NO]
        }
      })
      // find parent 
      const findParent = sortByDateOfBirthAscending(newDataStep2)[0]['idBeneficiari']
      dispatch(
        updateProps([
          {
            prop: BASE.KEY_CONTRACT_ID,
            value: res.data.id
          },
          {
            prop: BASE.KEY_CONTRACT_CODE,
            value: res.data.contractCode
          },
          {
            prop: BASE.KEY_COMPANY_ID,
            value: null
          },
          {
            prop: BASE.KEY_CONTRACT_INFO,
            value: res.data
          },
          {
            prop: BASE.KEY_ACTIVE_STEP,
            value: 2
          },
          {
            prop: KEY_INSURANCE_INFO,
            value: insurances
          },
          {
            prop: KEY_PERSON_SELECT,
            value: findParent
          },
          {
            prop : KEY_STEP_2 ,
            value : {'addtinalPeople' : newDataStep2 , startValueDate : moment(new Date).add(plusDate , 'd').utc(true).format('YYYY-MM-DD'),
            endValueDate : moment(new Date).add(plusDate , 'd').add(1, 'y').subtract(1 , 'day').utc(true).format('YYYY-MM-DD'),  formatCard : ELECTROMAGNETIC_CARD,}
          }
        ])
      )
    } catch (e) {
      console.log(e)
    }
  }
}

export const getDefault_createContractObj = (values, companyId , beneficiaries , ownerId) => {
  const {
    [KEY_ADDTIONAL_PEOPLE]: addtinalPeople,
    [KEY_IC_NO]: icNo,
    [KEY_FULLNAME]: fullName,
    [KEY_DATE_BIRTH]: dateOfBirth,
    [KEY_PHONE_NUMBER]: phoneNumber,
    [KEY_EMAIL]: email,
    [KEY_CITY]: city,
    [KEY_DISTRICT]: district,
    [KEY_WARD]: ward,
    [KEY_ADDRESS]: address
  } = values
  const _beneficiaries = addtinalPeople.map((elt , index) => {
    const _Age = moment().diff(
      moment(elt[KEY_DATE_BIRTH]).format('YYYY-MM-DD'),
      'years'
    )
    return {
      id : beneficiaries ? beneficiaries[index]['id'] : undefined,
      address: elt[KEY_ADDRESS],
      isBuyer: false,
      insured: true,
      dateOfBirth: moment(elt[KEY_DATE_BIRTH]).utc(true).format('YYYY-MM-DD'),
      fullName: elt[KEY_FULLNAME],
      icType: elt[KEY_IC_TYPE],
      gender: elt[KEY_GENDER],
      icNo: elt[KEY_IC_NO],
      relationship: elt[KEY_RELATIONSHIP],
      parentNameInsured: _Age < 18 ? elt[KEY_NUMBER_GCNBH_PARENTS] : '',
      nationality: elt[KEY_NATIONALITY],
      insuredType : elt[KEY_INSURANCE_TYPE],
      baFileContent : elt[KEY_FILE_CONTENT] && {
        [KEY_FILE_CONTENT] : elt[KEY_FILE_CONTENT].split(',')[1],
        [KEY_FILE_TYPE] : elt[KEY_FILE_TYPE],
        [KEY_FILE_NAME] : elt[KEY_FILE_NAME]
      } || null
    }
  })
  return [
    {
      id : ownerId ? ownerId : undefined,
      addresses: [
        {
          city: city,
          detail: address,
          district: district,
          ward: ward,
          isDefault: true,
          type: 'HOME'
        }
      ],
      email: email,
      fullName: fullName,
      dateOfBirth: moment(dateOfBirth).utc(true).format('YYYY-MM-DD'),
      phoneNumber: phoneNumber,
      isBuyer: true,
      icNo: icNo
    },
    ..._beneficiaries
  ]
}

export const API_BENEFICIARIES =
  '/nth/personalinsurance/api/contracts/ba-contract'
export const updateBeneficiaries = (contractId, data , values) => {
  return async (dispatch, getState) => { 
    const {[KEY_STEP_2] : step_2} = getState()["app"][NAME_BUY_INSURANCES_BVAG]
    try {
      const res = await HttpClient.put(
        `${API_BENEFICIARIES}?contractId=${contractId}`,
        data
      )
      if (res.status !== 200) return
      const insurances = res.data.insurances[0]
      const newDataStep2 = step_2[KEY_ADDTIONAL_PEOPLE].map((_ben , index) => {
        // find item by ic no
        const findItem = values[KEY_ADDTIONAL_PEOPLE].find(_item => _item[KEY_IC_NO] === _ben[KEY_IC_NO])
        return {
          ..._ben,
          gender : findItem?.gender,
          relationship :  findItem?.relationship
        }
      })

      dispatch(
        updateProps([
          {
            prop: BASE.KEY_ACTIVE_STEP,
            value: 2
          },
          {
            prop: BASE.KEY_CONTRACT_INFO,
            value: res.data
          },
          {
            prop: KEY_INSURANCE_INFO,
            value: insurances
          },
          {
            prop : KEY_STEP_2 ,
            value : {...step_2 , [KEY_ADDTIONAL_PEOPLE] : newDataStep2}
          }       
        ])
      )
    } catch (e) {
      console.log(e)
    }
  }
}

export const updateContract = async (contractId, duration) => {
  const res = await HttpClient.put(
    `${API_CONTRACTS}?contractId=${contractId}`,
    duration
  )
  if (hasRequestFail(res.status)) return
}

export const getDefault_updateBeneficiariesObj = (
  values,
  contractInfo,
  companyId
) => {
  let { owner, beneficiaries , ownerId } = contractInfo
  if (isObjEmpty(owner)) {
    owner = { id: '', addresses: [{ id: '' }] }
  }
  if (isArrayEmpty(beneficiaries)) {
    beneficiaries = [{ id: '', addresses: [{ id: '' }] }]
  }
  const arr = getDefault_createContractObj(values, companyId , beneficiaries , ownerId)
  return arr
}
export const bigger_18_age = '> 18'
export const smaller_18_age = '< 18'
export const _RELATIONSHIPS = [
  {
    label: <FormattedMessage id={getKeyLang(`myself`)} />,
    content: RELATIONSHIP_SELF,
    value: 0,
    maxAge : 18,
    condition : undefined
  },
  {
    label: <FormattedMessage id={getKeyLang(`bvag.relashiption.couple`)} />,
    content: 'VC',
    value: 1,
    maxAge : 18,
    condition : bigger_18_age
  },
  {
    label: <FormattedMessage id={getKeyLang(`bvag.relashiption.child`)} />,
    content: 'CON',
    value: 2,
    maxAge : 18,
    condition : smaller_18_age
  },
  {
    label: (
      <FormattedMessage
        id={getKeyLang(`bvag.relashiption.FatherMotherGuardian`)}
      />
    ),
    content: 'BM',
    value: 3,
    maxAge : 18,
    condition : bigger_18_age
  },
  {
    label: (
      <FormattedMessage
        id={getKeyLang(`bvag.relashiption.Spouse'sFatherMother`)}
      />
    ),
    content: 'FATHER_IN_LAW',
    value: 4,
    maxAge : 18,
    condition : bigger_18_age
  }
]
export const QUESTION_TYPE_STRING = 'STRING'
export const QUESTION_TYPE_NUMBER = 'NUMBER'
export const QUESTION_TYPE_DATE = 'DATE'
export const QUESTION_TYPE_BOOLEAN = 'BOOLEAN'
export const QUESTION_TYPE_MULTI = 'MULTI_ANSWER'
export const API_GET_QUESTION =
  '/nth/personalinsurance/api/bv-an-gia/surveys/template'
export const API_UPLOAD_FILE = '/nth/file/api/upload'
export const API_SURVEYS = '/nth/personalinsurance/api/bv-an-gia/surveys'
export const API_CHECK_VALIDATE_QUESTION =
  '/nth/personalinsurance/api/happy-life/surveys/validation'
export const checkFieldEmpty = (insureds) => {
  let result
  insureds.forEach((_elt) => {
    if (
      _elt.username === null ||
      _elt.icNumber === null ||
      _elt.dateOfBirth === null ||
      _elt.username === '' ||
      _elt.icNumber === '' ||
      _elt.dateOfBirth === ''
    ) {
      result = true
    } else {
      result = false
    }
  })
  return result
}
export const checkSpecialCharacters = (insureds) => {
  let result
  insureds.forEach((_elt) => {
    // CHECK KÍ TỰ ĐẶC BIỆT USERNAME
    const _reg_num = /\d/
    const _reg_c =
      /[^\w ÀÁÂÃÈÉÊẾÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêếềìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹý\s]/
    if (_reg_num.test(_elt.username) || _reg_c.test(_elt.username)) {
      result = true
    } else {
      result = false
    }
  })
  return result
}

export const check15ageTo65age = (insureds) => {
  let result
  insureds.forEach((_elt) => {
    const days = moment().diff(
      moment(_elt.dateOfBirth).format('YYYY-MM-DD'),
      'days'
    )
    const years = moment().diff(
      moment(_elt.dateOfBirth).format('YYYY-MM-DD'),
      'years'
    )
    if (days < 15 || years > 65) {
      result = true
    } else {
      result = false
    }
  })
  return result
}


export const check6age= (insureds) => {
  let result
  insureds.forEach((_elt) => {
    const years = moment().diff(
      moment(_elt.dateOfBirth).format('YYYY-MM-DD'),
      'years' , true
    )
    if (years <= 6) {
      result = true
    }
  })
  return result
}

export const checkPersorn6age= (person) => {
  const age = moment().diff(
    moment(person.dateOfBirth).format('YYYY-MM-DD'),
    'years' , true
  )
  if (age < 6) {
    return true
  }
}
export const insuredsArr = [
  {
    content : "Mới" ,
    value : "NEW"
  },
  {
    content : "Tái tục" ,
    value : "RENEWALS"
  },
]
export const API_GET_NATIONALITY = '/nth/personalinsurance/api/authenticate/ba-nationality'
export const checkRelationshipByAge = (person) => {
  let relationships = _RELATIONSHIPS
  const age = moment().diff(
    moment(person.dateOfBirth).format('YYYY-MM-DD'),
    'years'
  )
  if (age < 18 && !person[KEY_IS_SELF]) {
    relationships = _RELATIONSHIPS.filter((relationship) => {
      return relationship.condition === smaller_18_age;
    });
  }
  if (age > 18 && !person[KEY_IS_SELF]) {
    relationships = _RELATIONSHIPS.filter((relationship) => {
      return relationship.content !== RELATIONSHIP_SELF;
    });
  }
  if (age > 18 && person[KEY_IS_SELF]) {
    relationships = _RELATIONSHIPS.filter((relationship) => {
      return relationship.content === RELATIONSHIP_SELF;
    });
  }
  return relationships
}
export const checkRelationshipBM = (insureds , parentIcNo) => {
  let result
  insureds.forEach((_elt) => {
    if (_elt[KEY_IC_NO] === parentIcNo) {
      result = true
    }
  })
  return result
  
}
export const checkRelationshipCON = (insureds) => {
  let result
  insureds.forEach((_elt) => {
    if (_elt[KEY_RELATIONSHIP] === 'CON') {
      result = true
    }
  })
  return result
}
export const checkParentHash = (insureds) => {
  let result
  insureds.forEach((_elt) => {
    if (_elt[KEY_RELATIONSHIP] === 'BM') {
      result = true
    }
  })
  return result
}

export const checkAllAnswerIsNo = (contexts ) => {
  return contexts.every((item) => {
    return item.answer !== true;
  });
}
export const checkAnswerIsTrue = (questions = []) => {
  return questions.some((question) => question.answer === true);
}

export const areKeyAndValueNull = (obj) =>  {
  return obj.key === null || obj.key ==='' || obj.value === null || obj.value === 'null';
}