import {
  getDefault_buyInsuranceReducer,
  getDefault_initialState
} from './utility'
import { PATH_TBA_INSUR } from '../../../configs/insurance-app'
import * as _ from './utility'
import moment from 'moment'
import { PAYMENT_TYPE_FUND_TRANSFER } from '../../../components/insurance-app/buy-insurances-page/formik-config'
import { NAME_BUY_INSURANCES_TBA, COMPANIES, PAID_WAITING } from "../../../configs/insurance-app"

export const REDUX_STATE_NAME = NAME_BUY_INSURANCES_TBA
export const ACTION_BUY_INSUR_TBA_UPDATE_PROPS =
  'ACTION_BUY_INSUR_TBA_UPDATE_PROPS'
export const ACTION_BUY_INSUR_TBA_RESET_ALL =
  'ACTION_BUY_INSUR_TBA_RESET_ALL'

export const BASE = _
export const MAX_STEP = 4
export const KEY_INSURANCE_INFO = 'insuranceInfo';
export const KEY_STEP_1 = 'step_1';
export const KEY_STEP_2 = 'step_2';
export const BENEFICIARY = 'beneficiary'
export const KEY_IC_TYPE = 'icType'
export const KEY_IC_NO = 'icNo'
export const KEY_FULLNAME = 'fullname'
export const KEY_ADDRESS = 'address'
export const KEY_BENEFICIARY = 'beneficiary'
export const initialState = getDefault_initialState()

export const KEY_ACTIVE_STEP = "activeStep"
export const KEY_BUYER_TYPE = "buyerType"
export const KEY_CONTRACT_ID = "contractId"
export const KEY_CONTRACT_INFO = "contractInfo"
export const KEY_CONTRACT_CODE = "contractCode"
export const KEY_DATA_FEES = "dataFees"
export const KEY_HAS_CAL_FEE_DONE = "hasCalFeeDone"
export const KEY_PAYMENT_TYPE = "paymentType"
export const KEY_TOTAL_FEE = "totalFee"
export const KEY_PAY_CONTRACT_STATUS = "payContractStatus"
export const KEY_AGREED_TERM_OF_SERVICES_STATUS = "agreedTermOfServicesStatus"
export const KEY_COMPANY_ID = "companyId"
export const KEY_CONTRACT_VALUE = "contractValue"
export const KEY_CLIENT_MESSAGE_ID = 'clientMessageId'
export const KEY_CHECKED_OTP = 'checkedOtp';
export const KEY_BENEFICIARY_FIRST = 'beneficiaryFirst'
export const KEY_BENEFICIARY_SECOND = 'beneficiarySecond'
export const KEY_BRANCH_NAME = 'branchName'
export const KEY_BANK_NAME = 'bankName'

export const beneficiaryFirst = {
  [KEY_BRANCH_NAME]: '',
  [KEY_BANK_NAME]: '',
}

export const beneficiarySecond = {
  [KEY_FULLNAME]: '',
  [KEY_IC_TYPE]: '',
  [KEY_IC_NO]: '',
  [KEY_ADDRESS]: ''
}

const ptiCompany = COMPANIES.find(elt => elt.name === "PTI")

initialState[KEY_STEP_2] = {
  responsibility: '',
  duration: 12,
  packageSelected: 'LIGHT',
  dateInsuranceFrom: moment().utc(true).add(2, 'd').format('YYYY-MM-DD'),
  dateInsuranceTo: moment().utc(true).add(1, 'y').format('YYYY-MM-DD'),
  [KEY_BENEFICIARY_FIRST]: beneficiaryFirst,
  [KEY_BENEFICIARY_SECOND]: beneficiarySecond,
  [KEY_CONTRACT_INFO]: {},
  [KEY_COMPANY_ID]: ptiCompany.id,
  [KEY_CONTRACT_ID]: ``,
  [KEY_CONTRACT_CODE]: ``,
  [KEY_CLIENT_MESSAGE_ID]: '',
  [KEY_DATA_FEES]: [],
  [KEY_HAS_CAL_FEE_DONE]: false,
  [KEY_PAYMENT_TYPE]: PAYMENT_TYPE_FUND_TRANSFER,
  [KEY_TOTAL_FEE]: 0,
  [KEY_PAY_CONTRACT_STATUS]: PAID_WAITING,
  [KEY_AGREED_TERM_OF_SERVICES_STATUS]: false,
  [KEY_COMPANY_ID]: '09',
}

initialState[KEY_INSURANCE_INFO] = [];
initialState[_.KEY_LAST_ENDPOINT_PATH] = PATH_TBA_INSUR;
initialState[KEY_CHECKED_OTP] = false;

const reducer = getDefault_buyInsuranceReducer(
  initialState,
  ACTION_BUY_INSUR_TBA_UPDATE_PROPS,
  ACTION_BUY_INSUR_TBA_RESET_ALL
)

export default reducer