import { HttpClient } from 'base-app'
import moment from 'moment'
import { PAYMENT_TYPE_FUND_TRANSFER } from '../../../../../../components/insurance-app/buy-insurances-page/formik-config'
import { API_CREATE_CONTACT, NAME_BUY_INSURANCES_HEALTH_CARE } from '../../../../../../configs/insurance-app'
import { updateProps } from '../../../../../../redux/actions/insurance-app/buyInsurancesAntin'
import { BASE, KEY_STEP_2 } from '../../../../../../redux/reducers/insurance-app/buyInsurancesAntin'
import { hasRequestFail, isArrayEmpty } from '../../../../../../ultity'
import {
  KEY_ADDRESS, KEY_ADDRESS_TAX,
  KEY_ADDTIONAL_PEOPLE, KEY_BUY_PEOPLE, KEY_BUYER_TYPE,
  KEY_CITY, KEY_CITY_TAX,
  KEY_CREDIT_CONTRACT_NO,
  KEY_CREDIT_DURATION,
  KEY_DATE_BIRTH,
  KEY_DISTRICT,
  KEY_DISTRICT_TAX,
  KEY_EMAIL, KEY_FAX_CODE,
  KEY_FULLNAME,
  KEY_GENDER,
  KEY_IC_NO,
  KEY_IC_TYPE,
  KEY_LOAN,
  KEY_PHONE_NUMBER, KEY_REFERRER,
  KEY_WARD, KEY_WARD_TAX
} from './formikConfig'
import { KEY_DURATION_SELECTED, KEY_PACKAGE_SELECTED } from '../../../tinbaoan-insurance/components/step1/formikConfig'
import { KEY_RELATIONSHIPS } from '../../../tinbaoan-insurance/components/step1/formikConfig'
export const API_CONTRACTS_TD = "nth/personalinsurance/api/td-mic/payment-infos/update-payment"

export const API_CONTRACTS_TD_CONFIRM_PAYCONTACT = '/nth/personalinsurance/api/payment-infos/pay-contract'
export const RELATIONSHIP_SELF = 'SELF'
export const RELATIONSHIP_GRANDFATHER = 'GRANDFATHER'
export const RELATIONSHIP_GRANDMOTHER = 'GRANDMOTHER'
export const RELATIONSHIP_FATHER = 'FATHER'
export const RELATIONSHIP_MOTHER = 'MOTHER'
export const RELATIONSHIP_WIFE = 'WIFE'
export const RELATIONSHIP_HUSBAND = 'HUSBAND'
export const RELATIONSHIP_CHILD = 'CHILD'
export const RELATIONSHIP_OLDERBROTHER = 'OLDERBROTHER'
export const RELATIONSHIP_OLDERSISTER = 'OLDERSISTER'
export const RELATIONSHIP_BROTHER = 'BROTHER'
export const RELATIONSHIP_SISTER = 'SISTER'
export const RELATIONSHIP_GRANDCHILDREN = 'GRANDCHILDREN'
export const RELATIONSHIP_OTHER = 'OTHER'
export const CORP = 'CORPORATION';
export const CORPORATE = 'CORPORATE';
export const INDIV = 'INDIVIDUAL';

const createBuyerObj = (values) => {
  values.map((person) => {
    const { [KEY_IC_TYPE]: icType, [KEY_IC_NO]: icNo, [KEY_FULLNAME]: fullName,
      [KEY_DATE_BIRTH]: dateOfBirth, [KEY_PHONE_NUMBER]: phoneNumber, [KEY_EMAIL]: email,
      [KEY_WARD]: ward, [KEY_ADDRESS]: address,
      [KEY_CITY]: city, [KEY_DISTRICT]: district,
      [KEY_GENDER]: gender, [KEY_WARD_TAX]: wardTax, [KEY_ADDRESS_TAX]: addressTax,
      [KEY_CITY_TAX]: cityTax, [KEY_DISTRICT_TAX]: districtTax, [KEY_BUYER_TYPE]: buyerType, [KEY_FAX_CODE]: fax} = person
    if(buyerType === INDIV)
      return ({
        "icNo": icNo,
        "icType": icType,
        "email": email,
        "gender": gender,
        "fullName": fullName,
        "phoneNumber": phoneNumber,
        "dateOfBirth": moment(dateOfBirth).utc(true).toISOString(),
        "customerType": "INDIVIDUAL",
        "addressDetails": address,
        "city": city,
        "district": district,
        "ward": ward
      })
    else
      return ({
        "icNo": icNo,
        "icType": "MST",
        "fullName": fullName,
        "fax": fax,
        "customerType": CORP,
        "addressDetails": address,
        "city": city,
        "district": district,
        "ward": ward,
        "addressTax": addressTax
      })
  })
}

const createdBeneficiariesObj = (beneficiaries, values) => {
  const { [KEY_PACKAGE_SELECTED]: packageSelected, [KEY_DURATION_SELECTED]: durationSelected , [KEY_BUYER_TYPE]: buyerType } = values
  if(buyerType === INDIV)
  return beneficiaries.map((person) => {
    const { [KEY_IC_TYPE]: icType, [KEY_IC_NO]: icNo, [KEY_FULLNAME]: fullName,
      [KEY_DATE_BIRTH]: dateOfBirth, [KEY_GENDER]: gender,
      [KEY_PHONE_NUMBER]: phoneNumber, [KEY_EMAIL]: email, [KEY_RELATIONSHIPS]: relationship,
      [KEY_CITY]: city, [KEY_DISTRICT]: district, [KEY_WARD]: ward, [KEY_ADDRESS]: address } = person
    let selfBeneficiry = relationship === RELATIONSHIP_SELF;
    let today = new Date();

    today.setDate(today.getDate() + 2);

    today = today.toLocaleDateString('vi');
    let todate = today.setFullYear(today.getFullYear() + 1);
    return ({
      "icNo": icNo,
      "email": email,
      "icType": icType,
      "gender": gender,
      "fullName": fullName,
      "phoneNumber": phoneNumber,
      "selfBeneficiary": selfBeneficiry,
      "dateOfBirth": moment(dateOfBirth).utc(true).toISOString(),
      "customerType": "INDIVIDUAL",
      "insuranceInformations" : {
        "insurancePackage": "BASIC",
        "tenor": "12",
        "effectiveFromDate": today,
        "effectiveToDate": todate,
        "electronicGuaranteeNumber": "QLV12312",
        "electronicGuaranteePass": "PASS123123",
      },
      "addressDetail": address,
      "city": city,
      "district": district,
      "ward": ward,
    })
  })
}

export const getDefault_createdContractInfoObj = (values) => {
  alert("created contract")
  const { [KEY_ADDTIONAL_PEOPLE]: beneficiaries , [KEY_BUY_PEOPLE] : buyer} = values
  console.log(JSON.stringify([createBuyerObj(buyer), ...createdBeneficiariesObj(beneficiaries, values)]))
  return ([
    createBuyerObj(buyer),
    ...createdBeneficiariesObj(beneficiaries, values)
  ])
}
