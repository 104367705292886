import Service from '../../../services/insurance-app/buyInsuranceTinbaoaan'
import { hasRequestFail } from '../../../ultity'
import {
    ACTION_BUY_INSUR_TBA_RESET_ALL, ACTION_BUY_INSUR_TBA_UPDATE_PROPS,
    BASE,
    KEY_CONTRACT_CODE,
    KEY_CONTRACT_ID,
    KEY_DATA_FEES,
    KEY_HAS_CAL_FEE_DONE,
    MAX_STEP, KEY_CLIENT_MESSAGE_ID, KEY_ACTIVE_STEP, KEY_PAY_CONTRACT_STATUS
} from '../../reducers/insurance-app/buyInsurancesTBA'
import {  setLoadingStatus } from './appConfig'
import { PAID_WAITING} from "../../../configs/insurance-app";
import {BaseAppUltils} from "base-app";


export const createContract = (contractInfo, currentActiveStep) => {
  return async (dispatch) => {

   try {
       dispatch(setLoadingStatus(true))
       const response  = await Service.createContract(contractInfo);

       dispatch(setLoadingStatus(false))

       if (hasRequestFail(response.status)) {
           return;
       }


       if(200 === response.status) {
           dispatch(updateProps([{
               prop: KEY_CONTRACT_ID,
               value: response.data.id
           },
           {
               prop: KEY_CLIENT_MESSAGE_ID,
               value: BaseAppUltils.generateUUID()
           },
           {
               prop: KEY_CONTRACT_CODE,
               value: response.data.contractCode
           }
           ]));
           dispatch(nextStep(currentActiveStep));
       }

   } catch (e) {
      //  console.log(e);
   }
  }
}

export const updateProps = (properties) => {
  return (dispatch) => {
    dispatch(
      {
        type: ACTION_BUY_INSUR_TBA_UPDATE_PROPS,
        payload: properties
      }
    )
  }
}

export const resetState = () => {
  return (dispatch) => {
    dispatch({
      type: ACTION_BUY_INSUR_TBA_RESET_ALL,
    })
  }
}

export const backStep = (currentStep) => {
  return (dispatch) => {
    let _nextStep = --currentStep
    if (_nextStep < 1) {
      _nextStep = 1
    }

    dispatch(
      {
        type: ACTION_BUY_INSUR_TBA_UPDATE_PROPS,
        payload: [
          {
            prop: BASE.KEY_ACTIVE_STEP,
            value: _nextStep
          },
          {
            prop: BASE.KEY_HAS_CAL_FEE_DONE,
            value: false
          },
        ]
      }
    )
  }
}

export const nextStep = (currentStep) => {
  return (dispatch) => {
    let _nextStep = ++currentStep
    if (_nextStep > MAX_STEP) {
      _nextStep = MAX_STEP
    }

    dispatch(
      {
        type: ACTION_BUY_INSUR_TBA_UPDATE_PROPS,
        payload: [
          {
            prop: BASE.KEY_ACTIVE_STEP,
            value: _nextStep
          }
        ]
      }
    )
  }
}

export const updateContract = ( contractInfo, currentActiveStep) => {
  return async (dispatch) => {
    dispatch(setLoadingStatus(true))

    const res = await Service.updateContract(contractInfo)

    dispatch(setLoadingStatus(false))

    if (hasRequestFail(res.status)){
        return
    }

    if (200 === res.status) {
        dispatch(nextStep(currentActiveStep));
    }
  }
}

export const getFee = (contactId) => {
  return async (dispatch) => {
      dispatch(setLoadingStatus(true));
      const res = await Service.getFee(contactId);
      dispatch(setLoadingStatus(false));
      if (hasRequestFail(res.status)) {
          // console.log('Lỗi khi lấy dữ liệu phí:', res.status);
          return;
      }
      dispatch({
          type: ACTION_BUY_INSUR_TBA_UPDATE_PROPS,
          payload: [
              {
                  prop: KEY_HAS_CAL_FEE_DONE,
                  value: true
              },
              {
                  prop: KEY_DATA_FEES,
                  value: res.data
              },
          ]
      });
  };
};

export function pay(res,keyMaps = {}) {
    return async (dispatch) => {
        const { ACTION_BUY_INSUR_TBA_UPDATE_PROPS, KEY_ACTIVE_STEP, MAX_STEP, KEY_PAY_CONTRACT_STATUS } = keyMaps
        if (hasRequestFail(res.status)) return
        const { url } = res.data
        if(url === '') {
            dispatch({
                type: ACTION_BUY_INSUR_TBA_UPDATE_PROPS,
                payload: [
                    {
                        prop: KEY_ACTIVE_STEP,
                        value: MAX_STEP
                    },
                    {
                        prop: KEY_PAY_CONTRACT_STATUS,
                        value: PAID_WAITING
                    },
                ]
            })
        }
        else{
            window.location.assign(url)
        }
    }
}

export function findOne(contractId, setContract, setContractValue) {
    return async (dispatch) => {
        dispatch(setLoadingStatus(true));
        const response = await Service.getContractDetails(contractId);
        dispatch(setLoadingStatus(false))
        if (hasRequestFail(response.status)) {
            return;
        }
        if (200 === response.status) {
            setContract(response.data);
            setContractValue(response.data.contractValue)
        }
    }
}

export function updatePaymentType(contractId, paymentType) {

    const payload = {
        contractId,
        paymentType
    }

    return async (dispatch) => {
        dispatch(setLoadingStatus(true));
        const response = await Service.updatePaymentInfo(payload);
        dispatch(setLoadingStatus(false));

        if (hasRequestFail(response.status)) {
            return
        }
    }
}

export function payContract(contractId, paymentType) {

    return async(dispatch) =>{
        dispatch(setLoadingStatus(true));
        const response = await Service.payContract(contractId, paymentType);
        dispatch(setLoadingStatus(false));

        if (hasRequestFail(response.status)) {
            return
        }

        if(200 === response.status) {
            dispatch(
                pay(response, {
                    ACTION_BUY_INSUR_TBA_UPDATE_PROPS, KEY_ACTIVE_STEP, MAX_STEP, KEY_PAY_CONTRACT_STATUS
                })
            )
        }
    }
}

export const getProposalForm = (contractCode) => {
  return async (dispatch) => {
    try {
      dispatch(setLoadingStatus(true))
      const response = await Service.getProposalForm(contractCode)
      dispatch(setLoadingStatus(false))
      return response
    } catch (error) {
      dispatch(setLoadingStatus(false))
      console.error("Lỗi khi lấy yêu cầu bảo hiểm:", error)
      throw error
    }
  }
}
