import React from 'react'
import moment from 'moment'
import * as Yup from 'yup'
import * as config from '../../../../../../components/insurance-app/buy-insurances-page/formik-config'
import {
  citizenIndentifyRegex,
  personalIdRegex_TBA
} from '../../../../../../components/insurance-app/buy-insurances-page/formik-config'
import {isArrayEmpty, sleepingFor} from '../../../../../../ultity'
import {FormattedMessage} from 'react-intl'
import {getKeyLang} from '../../../../../../configs/insurance-app'
import { CORP, INDIV } from './utility'
import {KEY_CLIENT_MESSAGE_ID} from '../../../../../../redux/reducers/insurance-app/buyInsurancesTBA'

export const IDTypes = [
  {
    label: <FormattedMessage id={getKeyLang("IDCMND")} />,
    content: config.ID_TYPE_CMND,
    value: 1,
  },
  {
    label: <FormattedMessage id={getKeyLang(`IDCCCD`)} />,
    content: config.ID_TYPE_CCCD,
    value: 2,
  }
]



export const BuyerTypes = [
  {
    label: <FormattedMessage id={getKeyLang(`tba.buyerType.individual`)} />,
    value: "INDIVIDUAL",
    content: config.BUYER_TYPE_INDIVIDUAL
  },
  {
    label: <FormattedMessage id={getKeyLang(`tba.buyerType.corporation`)} />,
    value: "CORPORATION",
    content: config.BUYER_TYPE_CORPORATION
  }
]

export const relationships = [
  {
    label: <FormattedMessage id={getKeyLang(`myself`)} />,
    content: "BT",
    value: 1,
  },
  {
    label: <FormattedMessage id={getKeyLang(`parent`)} />,
    content: "BM",
    value: 2,
  },
  {
    label: <FormattedMessage id={getKeyLang(`couple`)} />,
    content: "VC",
    value: 3,
  },
  {
    label: <FormattedMessage id={getKeyLang(`child`)} />,
    content: "CON",
    value: 4,
  },
  {
    label: <FormattedMessage id={getKeyLang(`siblings`)} />,
    content: "ACE",
    value: 5,
  },
  {
    label: <FormattedMessage id={getKeyLang(`enterprise`)} />,
    content: "DN",
    value: 6,
  },
]

export const sugg_gender = [
  {
    value: "1",
    content: "MALE",
    label: <FormattedMessage id={getKeyLang(`Male`)} />
  },
  {
    value: "2",
    content: "FEMALE",
    label: <FormattedMessage id={getKeyLang(`Female`)} />,
  },
  {
    value: "3",
    content: "OTHER",
    label: <FormattedMessage id={getKeyLang(`Other`)} />,
  }
]

export const KEY_ADDTIONAL_PEOPLE = "addtionalPeople"
export const KEY_BUY_PEOPLE = "buyPeople"
export const KEY_IC_TYPE = "icType"
export const KEY_BUYER_TYPE = "buyerType"
export const KEY_RELATIONSHIPS = "relationshipWithBuyer"
export const KEY_SELF_BENEFICIARY = "selfBeneficiary"

export const KEY_IC_NO = "icNo"
export const KEY_FULLNAME = "fullname"
export const KEY_DATE_BIRTH = "dateOfBirth"
export const KEY_PHONE_NUMBER = "phoneNumber"
export const KEY_EMAIL = "email"
export const KEY_GENDER = "gender"
export const KEY_HAVE_BOUGHT_FOR_ME = "haveBoughtForMe"
export const KEY_RELATIONSHIP = 'relationship'
export const KEY_BANK = "bank"
export const KEY_STK  = "accountNumber"
export const KEY_ACCOUNT_NUMBER = 'cardNumber'
export const KEY_CLICKED_CLOSE_POPUP = 'clickedPopup'
export const KEY_CREDIT_CONTRACT_NO = 'creditContractNo'
export const KEY_LOAN  = 'loan'
export const KEY_CREDIT_DURATION  = 'creditDuration'
export const KEY_CITY = config.KEY_CITY
export const KEY_REFERRER = 'referrer'
export const KEY_DISTRICT = config.KEY_DISTRICT
export const KEY_WARD = config.KEY_WARD
export const KEY_ADDRESS = config.KEY_ADDRESS
export const KEY_MST = "MST";
export const KEY_CITY_TAX = config.KEY_CITY+"Tax"
export const KEY_DISTRICT_TAX = config.KEY_DISTRICT+"Tax"
export const KEY_WARD_TAX = config.KEY_WARD+"Tax"
export const KEY_ADDRESS_TAX = config.KEY_ADDRESS+"Tax"
export const KEY_FAX_CODE = "fax"
export const KEY_CUSTOMER_CODE = 'customerCode'
export const KEY_PACKAGE_SELECTED = 'packageName'
export const KEY_DURATION_SELECTED = 'duration'
export const KEY_CURRENT_FEE = 'currentFee'
export const KEY_START_INSURANCE = 'startValueDate'
export const KEY_END_INSURANCE = 'endValueDate'
export const KEY_RESPONSIBILITY = 'responsibility'
export const KEY_DATE_INSUR_FROM = 'dateInsuranceFrom'
export const KEY_TIME_INSUR_FROM = 'timeInsuranceFrom'
export const KEY_DATE_INSUR_TO = 'dateInsuranceTo'
export const KEY_TIME_INSUR_TO = 'timeInsuranceTo'
export const KEY_DURATION = 'duration'
export const KEY_BENEFICIARY = 'beneficiary'
export const KEY_BRANCH_NAME = 'branchName'
export const KEY_BANK_NAME = 'bankName'
export const KEY_BENEFICIARY_FIRST = 'beneficiaryFirst'
export const KEY_BENEFICIARY_SECOND = 'beneficiarySecond'
export const KEY_ELECTRONIC_GUARANTEE_NUMBER = "electronicGuaranteeNumber"
export const KEY_ELECTRONIC_GUARANTEE_PASS = "electronicGuaranteePass"
export const ALERT_MIN_18 = "Khách hàng phải trên 18 tuổi";
export const ALERT_MIN_18_MAX_72 = 'Tuổi phải từ trong khoảng 18 tới 72 tuổi'

export const beneficiaryFirst = {
  [KEY_BRANCH_NAME]: '',
  [KEY_BANK_NAME]: '',
}

export const beneficiarySecond = {
  [KEY_FULLNAME]: '',
  [KEY_IC_TYPE]: '',
  [KEY_IC_NO]: '',
  [KEY_ADDRESS]: ''
}

export const addtionalPeopleInitValue = ({
  [KEY_IC_TYPE]: "",
  [KEY_IC_NO]: "",
  [KEY_FULLNAME]: "",
  [KEY_DATE_BIRTH]: moment().subtract(18, "years").format("DD/MM/YYYY").toString(),
  [KEY_PHONE_NUMBER]: "",
  [KEY_EMAIL]: "",
  [KEY_GENDER]: "",

  [KEY_ACCOUNT_NUMBER]: "",
  [KEY_CITY]: "",
  [KEY_DISTRICT]: "",
  [KEY_WARD]: "",
  [KEY_ADDRESS]: "",
  [KEY_BUYER_TYPE]: "",
  [KEY_SELF_BENEFICIARY]: false
  })

export const buyPeopleInitValue = ({
  [KEY_IC_TYPE]: "",
  [KEY_IC_NO]: "",
  [KEY_FULLNAME]: "",
  [KEY_DATE_BIRTH]: moment().subtract(18,"years").format("DD/MM/YYYY").toString(),
  [KEY_PHONE_NUMBER]: "",
  [KEY_EMAIL]: " ",
  [KEY_GENDER]: "MALE",

  [KEY_CITY]: "",
  [KEY_DISTRICT]: "",
  [KEY_WARD]: "",
  [KEY_ADDRESS]: "",

  [KEY_CITY_TAX]: "",
  [KEY_DISTRICT_TAX]: "",
  [KEY_WARD_TAX]: "",
  [KEY_ADDRESS_TAX]: "",
  [KEY_FAX_CODE]: "",
  [KEY_BUYER_TYPE]: "",
  [KEY_SELF_BENEFICIARY]: false
})


export const initialValues = ({
  [KEY_CLICKED_CLOSE_POPUP] : false,
  [KEY_REFERRER]: "",
  [KEY_CUSTOMER_CODE]: "",
  [KEY_CLIENT_MESSAGE_ID]: "",
  // "electronicGuaranteeNumber": "QLV12312",
  //               "electronicGuaranteePass": "PASS123123"
  [KEY_ELECTRONIC_GUARANTEE_NUMBER]: "",
  [KEY_ELECTRONIC_GUARANTEE_PASS]: "",

  [KEY_ADDTIONAL_PEOPLE]: [
    { ...addtionalPeopleInitValue }
  ],

  [KEY_BUY_PEOPLE]: [
    { ...buyPeopleInitValue }
  ]
})

const isEmptyObject = (object) => {
  return Object.keys(object).length === 0;
}

export const validate = (values) => {
  return sleepingFor().then(() => {
    let errors = {}
    const {
      [KEY_ADDTIONAL_PEOPLE]: _addtinalPeople,
      [KEY_BUY_PEOPLE]: _buyPeople,
      [KEY_REFERRER]: referrer,
      [KEY_CUSTOMER_CODE]: customerId
    } = values;

    // if(customerId.match(config.nullStrRegex)) {
    //   errors[KEY_CUSTOMER_CODE] = config.ALERT_EMPTY
    // }

    /** init object to validate */
    if (!errors[KEY_BUY_PEOPLE]) {
      errors[KEY_BUY_PEOPLE] = [];
    }
    if (isArrayEmpty(errors[KEY_BUY_PEOPLE])) {
      for (let i = 0; i < _buyPeople.length; ++i) {
        errors[KEY_BUY_PEOPLE].push({})
      }
    }

    if (!errors[KEY_ADDTIONAL_PEOPLE]) {
      errors[KEY_ADDTIONAL_PEOPLE] = [];
    }
    if (isArrayEmpty(errors[KEY_ADDTIONAL_PEOPLE])) {
      for (let i = 0; i < _addtinalPeople.length; ++i) {
        errors[KEY_ADDTIONAL_PEOPLE].push({})
      }
    }

    _buyPeople.forEach((elt, index) => {


      /*validate common info buyer*/
      if(_buyPeople[0][KEY_BUYER_TYPE] === CORP){
        if(elt[KEY_FAX_CODE].match(config.nullStrRegex)) {
          errors[KEY_BUY_PEOPLE][index][KEY_FAX_CODE] = config.ALERT_EMPTY
        }

        if(!elt[KEY_IC_NO].match(config.taxCode)){
          errors[KEY_BUY_PEOPLE][index][KEY_IC_NO] = config.ALERT_INVALID
        }

        if(elt[KEY_IC_NO].match(config.nullStrRegex)) {
          errors[KEY_BUY_PEOPLE][index][KEY_IC_NO] = config.ALERT_EMPTY
        }

        // if(!elt[KEY_FAX_CODE].match(config.faxRegex)){
        //   errors[KEY_BUY_PEOPLE][index][KEY_FAX_CODE] = config.ALERT_INVALID
        // }

        if (!elt[KEY_CITY_TAX] || elt[KEY_CITY_TAX].match(config.nullStrRegex)) {
          if (!elt[KEY_ADDRESS_TAX] || elt[KEY_ADDRESS_TAX].match(config.nullStrRegex)) {
            errors[KEY_BUY_PEOPLE][index][KEY_ADDRESS_TAX] = config.ALERT_INVALID
          }
        }
      }else{
        if(!elt[KEY_IC_TYPE].match(config.nullStrRegex)){
           if(elt[KEY_IC_TYPE] === config.ID_TYPE_CMND){
              if(!elt[KEY_IC_NO].match(personalIdRegex_TBA)){
                errors[KEY_BUY_PEOPLE][index][KEY_IC_NO] = config.ALERT_INVALID;
              }
           }
          if(elt[KEY_IC_TYPE] === config.ID_TYPE_CCCD){
            if(!elt[KEY_IC_NO].match(citizenIndentifyRegex)){
              errors[KEY_BUY_PEOPLE][index][KEY_IC_NO] = config.ALERT_INVALID;
            }
          }
        }else errors[KEY_BUY_PEOPLE][index][KEY_IC_TYPE] = config.ALERT_EMPTY;

        if(elt[KEY_GENDER].match(config.nullStrRegex)){
          errors[KEY_BUY_PEOPLE][index][KEY_GENDER] = config.ALERT_EMPTY;
        }

        if(elt[KEY_EMAIL].match(config.nullStrRegex)){
          errors[KEY_BUY_PEOPLE][index][KEY_EMAIL] = config.ALERT_EMPTY;
        }

        if((elt[KEY_DATE_BIRTH] + '').match(config.nullStrRegex)){
          errors[KEY_BUY_PEOPLE][index][KEY_DATE_BIRTH] = config.ALERT_EMPTY;
        }

      }
      if (!elt[KEY_CITY] || elt[KEY_CITY].match(config.nullStrRegex)) {
        errors[KEY_BUY_PEOPLE][index][KEY_CITY] = config.ALERT_EMPTY
        if (!elt[KEY_DISTRICT] || elt[KEY_DISTRICT].match(config.nullStrRegex)) {
          errors[KEY_BUY_PEOPLE][index][KEY_DISTRICT] = config.ALERT_EMPTY
          if (!elt[KEY_WARD] || elt[KEY_WARD].match(config.nullStrRegex)) {
            errors[KEY_BUY_PEOPLE][index][KEY_WARD] = config.ALERT_EMPTY
            if (!elt[KEY_ADDRESS] || elt[KEY_ADDRESS].match(config.nullStrRegex)) {
              errors[KEY_BUY_PEOPLE][index][KEY_ADDRESS] = config.ALERT_EMPTY
            }
          }
        }
      }

      if(_buyPeople[0][KEY_BUYER_TYPE] === CORP){
        if (!elt[KEY_CITY_TAX] || elt[KEY_CITY_TAX].match(config.nullStrRegex)) {
          errors[KEY_BUY_PEOPLE][index][KEY_CITY_TAX] = config.ALERT_EMPTY
          if (!elt[KEY_DISTRICT_TAX] || elt[KEY_DISTRICT_TAX].match(config.nullStrRegex)) {
            errors[KEY_BUY_PEOPLE][index][KEY_DISTRICT_TAX] = config.ALERT_EMPTY
            if (!elt[KEY_WARD_TAX] || elt[KEY_WARD_TAX].match(config.nullStrRegex)) {
              errors[KEY_BUY_PEOPLE][index][KEY_WARD_TAX] = config.ALERT_EMPTY
              if (!elt[KEY_ADDRESS_TAX] || elt[KEY_ADDRESS_TAX].match(config.nullStrRegex)) {
                errors[KEY_BUY_PEOPLE][index][KEY_ADDRESS_TAX] = config.ALERT_EMPTY
              }
            }
          }
        }
      }
      /*check neu object ko loi xoa ngay khoi list errors*/
      if (isEmptyObject(errors[KEY_BUY_PEOPLE][index])) {
        errors[KEY_BUY_PEOPLE].splice(index, 1);
      }
    })

    _addtinalPeople.forEach((elt, index) => {

      /** validate address and city logic for additional people */
      if (!elt[KEY_CITY] || elt[KEY_CITY].match(config.nullStrRegex)) {
        errors[KEY_ADDTIONAL_PEOPLE][index][KEY_CITY] = config.ALERT_EMPTY
        if (!elt[KEY_DISTRICT] || elt[KEY_DISTRICT].match(config.nullStrRegex)) {
          errors[KEY_ADDTIONAL_PEOPLE][index][KEY_DISTRICT] = config.ALERT_EMPTY
          if (!elt[KEY_WARD] || elt[KEY_WARD].match(config.nullStrRegex)) {
            errors[KEY_ADDTIONAL_PEOPLE][index][KEY_WARD] = config.ALERT_EMPTY
            if (!elt[KEY_ADDRESS] || elt[KEY_ADDRESS].match(config.nullStrRegex)) {
              errors[KEY_ADDTIONAL_PEOPLE][index][KEY_ADDRESS] = config.ALERT_EMPTY
            }
          }
        }
      }
      if((elt[KEY_DATE_BIRTH] + '').match(config.nullStrRegex)){
        errors[KEY_ADDTIONAL_PEOPLE][index][KEY_DATE_BIRTH] = config.ALERT_EMPTY;
      } else {
        /** validate dateBirth >= 18 years */
        const { [KEY_DATE_BIRTH]: dateBirth } = elt
        if (moment(dateBirth).isAfter(moment().subtract(18, 'y'))) {
          errors[KEY_ADDTIONAL_PEOPLE][index][KEY_DATE_BIRTH] = ALERT_MIN_18_MAX_72;
        }
      }


      /*check neu object ko loi xoa ngay khoi list errors*/
      if (isEmptyObject(errors[KEY_ADDTIONAL_PEOPLE][index])) {
        errors[KEY_ADDTIONAL_PEOPLE].splice(index, 1);
      }
    })


    /*check neu object ko loi xoa ngay khoi list errors*/
    if (errors[KEY_BUY_PEOPLE].length === 0) {
      delete errors[KEY_BUY_PEOPLE];
    }

    if (errors[KEY_ADDTIONAL_PEOPLE].length === 0) {
      delete errors[KEY_ADDTIONAL_PEOPLE];
    }

    return errors;

  })

}

export const validationSchema = Yup.object().shape({
  [KEY_BUY_PEOPLE]: Yup.array()
      .of(Yup.object().shape({
        [KEY_FULLNAME]: Yup.string()
            .matches(config.nameRegex, config.ALERT_INVALID)
            .required(config.ALERT_INVALID)
        ,
        [KEY_PHONE_NUMBER]: Yup.string()
            .matches(config.phoneNumberRegex, config.ALERT_INVALID)
            .required(config.ALERT_INVALID)
        ,
        [KEY_IC_NO]: Yup.string()
            .matches(config.numberRegex, config.ALERT_INVALID)
            .required(config.ALERT_INVALID),
      })),

  [KEY_ADDTIONAL_PEOPLE]: Yup.array()
    .of(Yup.object().shape({
      [KEY_FULLNAME]: Yup.string()
        .matches(config.nameRegex, config.ALERT_INVALID)
        .required(config.ALERT_EMPTY).min(6,'Họ và tên không được ít hơn 5 kí tự ')
      ,
      ...config.getDefault_validateSchmaIcType(KEY_IC_TYPE, KEY_DATE_BIRTH)
      ,
      ...config.getDefault_validateSchemaIcNo(KEY_IC_NO, KEY_IC_TYPE, KEY_DATE_BIRTH)
      ,
      [KEY_PHONE_NUMBER]: Yup.string()
        .matches(config.phoneNumberRegex, config.ALERT_INVALID)
        .required(config.ALERT_EMPTY)
      ,
      [KEY_EMAIL]: Yup.string()
        .matches(config.emailRegex, config.ALERT_INVALID)
        .required(config.ALERT_EMPTY)
      ,
      [KEY_GENDER]: Yup.string()
        .required(config.ALERT_EMPTY)
      ,
      ...config.getDefault_addressessSchema(KEY_CITY, KEY_DISTRICT, KEY_WARD)
      ,
      [KEY_DATE_BIRTH]: Yup.string().when([KEY_BUYER_TYPE], {
        is: (value) => value !== CORP,
        then: Yup.string().test([KEY_BUY_PEOPLE][KEY_DATE_BIRTH],
          'Tuổi phải từ trong khoảng 18 tới 72 tuổi',
          (value) => {
            return ( !(moment(value).isAfter(moment().subtract(18, 'years'))) && !(moment(value).isBefore(moment().subtract(72, 'years'))) )
          }
        ),
        otherwise: Yup.string().notRequired()
      })
    }))

})