import moment from 'moment'
import * as Yup from 'yup'
import * as config from '../../../../../../components/insurance-app/buy-insurances-page/formik-config'

export const ALERT_EMPTY = config.ALERT_EMPTY
export const ALERT_MIN_VND = config.ALERT_MIN_VND

export const durationInitVal = 12
export const KEY_DURATION = "duration"
export const KEY_DATE_INSUR_FROM = "dateInsuranceFrom"
export const KEY_TIME_INSUR_FROM = "timeInsuranceFrom"
export const KEY_DATE_INSUR_TO = "dateInsuranceTo"
export const KEY_TIME_INSUR_TO = "timeInsuranceTo"
export const KEY_COUPON_CODE = "promoCode"
export const KEY_INSURANCE_AMOUNT = "insuranceAmount"
export const KEY_FEE_RATE_VALUE = "feeRateValue"
export const KEY_INSURANCE_FEE = "insuranceFee"
export const KEY_CREDIT_CONTRACT_NUMBER = "creditContractNumber"
const startDate = moment().utc(true)
    .add(2, 'days')
    .startOf('day')

const endDate = moment().utc(true)
    .add(2, 'days')
    .startOf('day')
    .add(durationInitVal, 'months')

export const initialValues = {
    [KEY_DURATION]: durationInitVal,
    [KEY_DATE_INSUR_FROM]: startDate.format(config.DATE_FORMAT),
    [KEY_TIME_INSUR_FROM]: null,
    [KEY_DATE_INSUR_TO]: endDate.format(config.DATE_FORMAT),
    [KEY_TIME_INSUR_TO]: null,
    [KEY_COUPON_CODE]: "",
    [KEY_INSURANCE_AMOUNT]: '',
    [KEY_FEE_RATE_VALUE]: `${(3).toFixed(2)}%`,
    [KEY_INSURANCE_FEE]: "",
    [KEY_CREDIT_CONTRACT_NUMBER]: "",
}

export const validationSchema = Yup.object().shape({
    [KEY_INSURANCE_AMOUNT]: Yup.number()
        .required(ALERT_EMPTY)
        .min(1, ALERT_MIN_VND),
    [KEY_CREDIT_CONTRACT_NUMBER]: Yup.string().required(ALERT_EMPTY).min(1, 'Ít nhất 1 ký tự').max(200, 'Không quá 200 ký tự'),
})