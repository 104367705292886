import { BaseAppUltils, FormattedMessage, HttpClient } from 'base-app'
import moment from 'moment'
import React from 'react'
import pviIcon from '../../../../../../assets/images/insurance-app/buy-insurance/Logo_PVI.svg'
import vbiIcon from '../../../../../../assets/images/insurance-app/buy-insurance/Logo_VBI_1.svg'
import vniIcon from '../../../../../../assets/images/insurance-app/buy-insurance/Logo_VNI.svg'
import xtiIcon from '../../../../../../assets/images/insurance-app/buy-insurance/Logo_XTI.svg'
import ptiIcon from '../../../../../../assets/images/insurance-app/buy-insurance/Logo_PTI.svg'
import { GENDER_TYPE_FEMALE, GENDER_TYPE_MALE } from '../../../../../../components/insurance-app/buy-insurances-page/formik-config'
import { getKeyLang } from '../../../../../../configs/insurance-app'
import { updateProps } from '../../../../../../redux/actions/insurance-app/buyInsurancesHappyLife'
import {
  BASE,
  KEY_INSURANCE_INFO,
  KEY_STEP_2
} from '../../../../../../redux/reducers/insurance-app/buyInsurancesHappyLife'
import {
  KEY_COMPANY_ID,
  KEY_CONTRACT_CODE,
  KEY_CONTRACT_INFO,
  KEY_TOTAL_FEE
} from '../../../../../../redux/reducers/insurance-app/utility'
import { hasRequestFail, isArrayEmpty } from '../../../../../../ultity'
import { API_CONTRACTS_HCAC } from '../step1/utility'
import { KEY_ADD_ONS, KEY_END_INSURANCE, KEY_PACKAGE_SELECTED, KEY_START_INSURANCE } from './formikConfig'
import bshIcon from '../../../../../../assets/images/insurance-app/buy-insurance/Logo_BSH_1.svg';
export const API_INSURANCES_HCAC =
  '/nth/personalinsurance/api/happy-life-insurance'
export const API_CONTRACTS_HCAC_FEE_INSURANCE_ =
  '/nth/personalinsurance/api/contract/happy-life/fee'

/** 
 * @example
 *  data = {
      "packageName": "GOI3",
      "duration": 12
    } 
*/
export const SILVER = 'SILVER'
export const TITAN = 'TITAN'
export const GOLD = 'GOLD'
export const PLATINUM = 'PLATINUM'
export const DIAMOND = 'DIAMOND'
export const LUXURY = 'LUXURY'

export const updateInsurancePackages = (contractId, data) => {
  return async (dispatch) => {
    try {
      const res = await HttpClient.post(
        `${API_INSURANCES_HCAC}?contractId=${contractId}`,
        data
      )
      const res2 = await HttpClient.get(
        `${API_CONTRACTS_HCAC_FEE_INSURANCE_}?contractId=${contractId}`,
        data
      )
      if (res.status === 200 && res2.status === 200) {
        dispatch(
          updateProps([
            {
              prop: BASE.KEY_ACTIVE_STEP,
              value: 2
            },
            {
              prop: KEY_INSURANCE_INFO,
              value: res.data
            },
            {
              prop: BASE.KEY_DATA_FEES,
              value: res2.data
            }
          ])
        )
      }
    } catch (e) {
      console.log(e)
    }
  }
}
export const updateInsurance = (contractId, data) => {
  return async (dispatch) => {
    try {
      const res = await HttpClient.put(
        `${API_INSURANCES_HCAC}?contractId=${contractId}`,
        data
      )
      if (hasRequestFail(res.status)) return

      const res2 = await HttpClient.get(
        `${API_CONTRACTS_HCAC_FEE_INSURANCE_}?contractId=${contractId}`,
        data
      )
      if (hasRequestFail(res.status)) return

      dispatch(
        updateProps([
          {
            prop: BASE.KEY_ACTIVE_STEP,
            value: 3
          },
          {
            prop: KEY_INSURANCE_INFO,
            value: res.data
          },
          {
            prop: BASE.KEY_DATA_FEES,
            value: res2.data
          }
        ])
      )
    } catch (e) {
      console.log(e)
    }
  }
}
export const getBodyDateInsurance = (start, end) => {
  return {
    startValueDate: moment(start).toISOString(),
    endValueDate: moment(end).toISOString()
  }
}

export const updateInsuranceAndFeeStep2 = (
  contractId,
  data,
  setCompaniesData,
  companyId,
  contractInfo,
  _addOns
) => {
  return async (dispatch ) => {
    try {
      const res = await HttpClient.put(
        `${API_INSURANCES_HCAC}?contractId=${contractId}`,
        data
      )
      if (res.status !== 200) return

      const res2 = await HttpClient.get(
        `${API_CONTRACTS_HCAC_FEE_INSURANCE_}?contractId=${contractId}`,
        data
      )
      if (res2.status !== 200) return

      dispatch(
        updateProps([
          {
            prop: KEY_INSURANCE_INFO,
            value: res.data
          },
          {
            prop: KEY_STEP_2,
            value: {...data , [KEY_ADD_ONS] : _addOns}
          },
          {
            prop: BASE.KEY_DATA_FEES,
            value: res2.data
          }
        ])
      )
      // set data fee again
      const foundCompany = res2.data.find((elt) => elt.companyId === companyId)
      if (!foundCompany) {
        const __companyIdDefault = res2.data[0].companyId
        dispatch(
          updateCompanyId(
            contractId,
            getDefault_updateCompanyIdContractObj(
              contractInfo,
              __companyIdDefault
            ),
            __companyIdDefault
          )
        )
      } else {
        dispatch(
          updateProps([
            {
              prop: KEY_COMPANY_ID,
              value: companyId
            }
          ])
        )
      }
      const newData = res2.data
        .filter((_elt) => enabledCompanies.includes(_elt.companyId))
        .map((item) => {
          const iconCompany = ARR_COMPANIES.find(
            (_elt) => _elt.companyId.toString() === item.companyId.toString()
          )
          return {
            ...item,
            logo: iconCompany !== undefined ? iconCompany.image : '',
            totalFeeInsurance: item.feeInsurances.feeInsurance
          }
        })
      //filter total fee
      const totalFee = newData.find(
        (_elt) => _elt?.companyId === companyId
      )?.feeInsurances?.feeInsurance
      dispatch(
        updateProps([
          {
            prop: KEY_TOTAL_FEE,
            value: totalFee
          }
        ])
      )
      setCompaniesData(newData)
    } catch (e) {
      console.log(e)
    }
  }
}
export const subStringMoney = (money, end) => {
  return money ? money.toString().slice(0, end) : 0
}

export const getDefault_updateCompanyIdContractObj = (
  contractInfo,
  companyId
) => {
  let _contractInfo = { ...contractInfo }
  delete _contractInfo['owner']
  delete _contractInfo['beneficiaries']
  delete _contractInfo['paymentType']
  delete _contractInfo['totalFeeInclVAT']
  _contractInfo['companyId'] = companyId
  return _contractInfo
}

export const updateCompanyId = (contractId, data, companyId) => {
  return async (dispatch) => {
    const res = await HttpClient.put(
      `/nth/personalinsurance/api/contract/happy-life/company?contractId=${contractId}&companyId=${companyId}`,
      {id : contractId} , {
        headers: { 'insuranceType': 'happy-life' }
    }
    )
    if (res.status === 200) {
      dispatch(
        updateProps([
          {
            prop: KEY_COMPANY_ID,
            value: companyId
          },
          {
            prop: KEY_CONTRACT_CODE,
            value: res.data.contractCode
          },
          {
            prop: KEY_CONTRACT_INFO,
            value: res.data
          }
        ])
      )
    } else {
      BaseAppUltils.toastError(
        'Cập nhật hãng bảo hiểm thất bại , vui lòng thử lại sau !'
      )
    }
  }
}

export const DURATION_PACKAGE = [
  {
    value: 12,
    year: 1,
    title: <FormattedMessage id={getKeyLang('insurance.heath.1year')} />
  },
  {
    value: 24,
    year: 2,
    title: <FormattedMessage id={getKeyLang('insurance.heath.2year')} />
  },
  {
    value: 36,
    year: 3,
    title: <FormattedMessage id={getKeyLang('insurance.heath.3year')} />
  }
]
export const ARR_COMPANIES = [
  {
    companyId: '01',
    id: '01',
    companyName: 'BSH',
    nameDetail: (
      <FormattedMessage id={getKeyLang('CompanyBSH')} />
    ),
    image: bshIcon
  },
  {
    companyId: '02',
    id: '02',
    companyName: 'VBI',
    nameDetail: (
      <FormattedMessage
        id={getKeyLang('BuyInsurance.Car.VBI')}
      />
    ),
    image: vbiIcon
  },
  {
    companyId: '03',
    id: '03',
    companyName: 'VNI',
    nameDetail: (
      <FormattedMessage id={getKeyLang('BuyInsurance.Car.VNI')} />
    ),
    image: vniIcon
  },
  {
    companyId: '04',
    id: '04',
    companyName: 'XTI',
    nameDetail: (
      <FormattedMessage
        id={getKeyLang('BuyInsurance.Car.XTI')}
      />
    ),
    image: xtiIcon
  },
  {
    companyId: '05',
    id: '05',
    companyName: 'PTI',
    nameDetail: (
      <FormattedMessage id={getKeyLang('BuyInsurance.Car.PTI')} />
    ),
    image: ptiIcon
  },
  {
    companyId: '06',
    id: '06',
    companyName: 'PVI',
    nameDetail: (
      <FormattedMessage
        id={getKeyLang('BuyInsurance.Car.PVI')}
      />
    ),
    image: pviIcon
  }
]
export const enabledCompanies = ['01','02' , '03' , '04' , '05' ,'06']

export const objectUpdate_Insurance = (values , contractId , birthday , addOns) => {
  let newAddons
  if (!isArrayEmpty(addOns) , addOns) {
    newAddons = addOns.filter((_elt)=> {
      if (_elt.isEnable) {
        return _elt.addonCode
      }
    }).map(_addons => _addons.addonCode)
  }
  const isToday = moment(values[KEY_START_INSURANCE]).isSame(moment(), 'day')
  const endTime =  moment(timeNow).subtract(1,'minutes')
  const timeNow =  moment().utc(true).format("H:mm")
  //if date now => time now <==> 00:00:00
  const _startDate = moment(values[KEY_START_INSURANCE]).utc(true).format(`YYYY-MM-DD ${isToday ? timeNow : '00:00:00'}`)
  const _endDate = moment(values[KEY_END_INSURANCE]).utc(true).format(`YYYY-MM-DD ${isToday ? moment(endTime).format('H:mm') : '23:59:59'}`)

  return {
    "productCode": values[KEY_PACKAGE_SELECTED],
    "description": "Bảo hiểm happy life",
    "birthday" :  moment(birthday).utc(true).format(`YYYY-MM-DD`)  ,
    "isEnable": true,
    "duration": 12,
    "startValueDate": moment(_startDate).utc(true).toISOString(),
    "endValueDate": moment(_endDate).utc(true).toISOString(),
    "contractId": contractId,
    "insuranceCode": "HL",
    "addOnCode": newAddons,
  }
}

export const _RANGE = [
  {
    value: 'BASIC',
    title: 'heath-care-advanced.range.bassic'
  },
  {
    value: 'ADVANCE',
    title: 'heath-care-advanced.range.advanced'
  },
  {
    value: 'BASIC',
    title: 'heath-care-advanced.range.bassic'
  },
  {
    value: 'ADVANCE',
    title: 'heath-care-advanced.range.advanced'
  }
]

export const Insurance_addOns = [
  {
    "addonCode": "OT",
    "description": "Quyền lợi Điều trị ngoại trú",
    "isEnable": true,
    "notApplyFor" : [],
    "minAge" : 0,
    "maxAge" : 99,
    "checkAge" : false,
    "gender" : [GENDER_TYPE_MALE , GENDER_TYPE_FEMALE ]
  },
  {
    "addonCode": "DC",
    "description": "Quyền lợi Chăm sóc răng (chỉ áp dụng khi đã tham gia điều trị ngoại trú)",
    "isEnable": false ,
    "notApplyFor" : [SILVER],
    "minAge" : 0,
    "maxAge" : 99,
    "checkAge" : false,
    "gender" : [GENDER_TYPE_MALE , GENDER_TYPE_FEMALE ]
  },
  {
    "addonCode": "M",
    "description": "Quyền lợi Thai sản",
    "isEnable": false,
    "notApplyFor" : [SILVER , TITAN , GOLD , PLATINUM],
    "minAge" : 18,
    "maxAge" : 45,
    "checkAge" : true,
    "gender" : [GENDER_TYPE_FEMALE]
  },
  {
    "addonCode": "DD",
    "description": "Quyền lợi Tử vong do ốm đau, bệnh tật",
    "isEnable": false,
    "notApplyFor" : [SILVER],
    "minAge" : 18,
    "maxAge" : 45,
    "checkAge" : true,
    "gender" : [GENDER_TYPE_MALE , GENDER_TYPE_FEMALE ]
  }
]