import { FormattedMessage, HttpClient } from 'base-app'
import React from 'react'
import { PAYMENT_TYPE_FUND_TRANSFER } from '../../../../../../components/insurance-app/buy-insurances-page/formik-config'
import { getKeyLang } from '../../../../../../configs/insurance-app'
import { updateProps } from '../../../../../../redux/actions/insurance-app/buyInsurancesHappyLife'
import { BASE, KEY_INSURANCE_INFO } from '../../../../../../redux/reducers/insurance-app/buyInsurancesHappyLife'
import { hasRequestFail, isArrayEmpty, isObjEmpty } from '../../../../../../ultity'
import { KEY_ADDRESS, KEY_ADDTIONAL_PEOPLE, KEY_CITY, KEY_DATE_BIRTH, KEY_DISTRICT, KEY_EMAIL, KEY_FULLNAME, KEY_GENDER, KEY_IC_NO, KEY_IC_NO_BENEFICIARY, KEY_IC_TYPE, KEY_IC_TYPE_BENEFICIARY, KEY_NAME_BENEFICIARY, KEY_NAME_PARENTS, KEY_PHONE_NUMBER, KEY_RELATIONSHIP, KEY_WARD } from './formikConfig'
import moment from 'moment'
import { API_ADD_FILE_INFO_HC_USER } from '../../../../../../configs/app-no1'
export const API_CONTRACTS_HCAC = "/nth/personalinsurance/api/contracts/happy-life"
export const RELATIONSHIP_SELF = 'SELF'
export const RELATIONSHIP_GRANDFATHER = 'GRANDFATHER'
export const RELATIONSHIP_GRANDMOTHER = 'GRANDMOTHER'
export const RELATIONSHIP_FATHER = 'FATHER'
export const RELATIONSHIP_MOTHER = 'MOTHER'
export const RELATIONSHIP_WIFE = 'WIFE'
export const RELATIONSHIP_HUSBAND = 'HUSBAND'
export const RELATIONSHIP_CHILD = 'CHILD'
export const RELATIONSHIP_OLDERBROTHER = 'OLDERBROTHER'
export const RELATIONSHIP_OLDERSISTER = 'OLDERSISTER'
export const RELATIONSHIP_BROTHER = 'BROTHER'
export const RELATIONSHIP_SISTER = 'SISTER'
export const RELATIONSHIP_GRANDCHILDREN = 'GRANDCHILDREN'
export const RELATIONSHIP_ACE = 'ACE'
export const RELATIONSHIP_ALIMONY = 'ALIMONY'
export const API_UPDATE_SURVEYS = '/nth/personalinsurance/api/happy-life/surveys'
export const createContract = (data , surveyId) => {
    return async (dispatch ) => {
        try {
            const res = await HttpClient.post(`${API_CONTRACTS_HCAC}`, data , {
                headers: { 'insuranceType': 'happy-life' }
            })
            if (res.status !== 200) return
            const insurances = res.data.insurances[0]
            const resSurveys = await HttpClient.put(`${API_UPDATE_SURVEYS}/${surveyId}?contract_id=${res.data.id}`)
            if (resSurveys.status !== 200) return
            dispatch(updateProps([
                {
                    prop: BASE.KEY_CONTRACT_ID,
                    value: res.data.id
                },
                {
                    prop: BASE.KEY_CONTRACT_CODE,
                    value: res.data.contractCode
                },
                {
                    prop: BASE.KEY_PAYMENT_TYPE,
                    value: PAYMENT_TYPE_FUND_TRANSFER
                },
                {
                    prop: BASE.KEY_COMPANY_ID,
                    value: null
                },
                {
                    prop: BASE.KEY_CONTRACT_INFO,
                    value: res.data
                },
                {
                    prop : BASE.KEY_ACTIVE_STEP ,
                    value : 2
                },
                {
                    prop : KEY_INSURANCE_INFO , 
                    value : insurances
                }
            ]))
        } catch (e) {
            console.log(e)
        }
    }
}

export const getDefault_createContractObj = (values , companyId) => {
    const {
        [KEY_ADDTIONAL_PEOPLE]: addtinalPeople,
        [KEY_IC_TYPE]: icType,
        [KEY_IC_NO]: icNo,
        [KEY_FULLNAME]: fullName,
        [KEY_DATE_BIRTH]: dateOfBirth,
        [KEY_PHONE_NUMBER]: phoneNumber,
        [KEY_EMAIL]: email,
        [KEY_GENDER]: gender,
        [KEY_CITY]: city,
        [KEY_DISTRICT]: district,
        [KEY_WARD]: ward,
        [KEY_ADDRESS]: address,
    } = values
    const _beneficiaries = addtinalPeople.map((elt) => {
        const _Age = moment().diff(moment(elt[KEY_DATE_BIRTH]).format('YYYY-MM-DD'), 'years')
        return ({
            "addresses": [
                {
                    "city": elt[KEY_CITY],
                    "detail": elt[KEY_ADDRESS],
                    "district": elt[KEY_DISTRICT],
                    "ward": elt[KEY_WARD],
                    "isDefault": true,
                    "type": "HOME",
                }
            ],
            "address": elt[KEY_ADDRESS],
            "isBuyer": false,
            "insured": true,
            "dateOfBirth": moment(elt[KEY_DATE_BIRTH]).utc(true).format('YYYY-MM-DD'),
            "fullName": elt[KEY_FULLNAME],
            "icType": elt[KEY_IC_TYPE],
            "gender": elt[KEY_GENDER],
            "icNo": elt[KEY_IC_NO],
            "relationship" : elt[KEY_RELATIONSHIP],
            "parentNameInsured" :_Age < 18 ? elt[KEY_NAME_PARENTS] : ''
        })
    })
    return (
        [
            {
                "addresses": [
                    {
                        "city": city,
                        "detail": address,
                        "district": district,
                        "ward": ward,
                        "isDefault": true,
                        "type": "HOME"
                    }
                ],
                "email": email,
                "fullName": fullName,
                "dateOfBirth": dateOfBirth,
                "phoneNumber": phoneNumber,
                "isBuyer": true,
                "icNo" : icNo
            },
          ..._beneficiaries
        ]
    )
}

export const API_BENEFICIARIES = "/nth/personalinsurance/api/contracts/happy-life"
export const updateBeneficiaries = (contractId, data) => {
    return async (dispatch, getState) => {
        try {
            const res = await HttpClient.put(`${API_BENEFICIARIES}?contractId=${contractId}`, data ,{
                headers: { 'insuranceType': 'happy-life' }
            })
            if (res.status !== 200) return
            const insurances = res.data.insurances[0]
            dispatch(updateProps([
                {
                    prop: BASE.KEY_ACTIVE_STEP,
                    value: 2
                },
                {
                    prop : BASE.KEY_CONTRACT_INFO,
                    value : res.data
                },
                {
                    prop : KEY_INSURANCE_INFO , 
                    value : insurances
                }
              
            ]))
        } catch (e) {
            console.log(e)
        }
    }
}

export const updateContract = async (contractId , duration) => {
    const res = await HttpClient.put(`${API_CONTRACTS_HCAC}?contractId=${contractId}` , duration)
    if (hasRequestFail(res.status)) return

    // dispatch(updateProps([
    //     {
    //         prop: BASE.KEY_ACTIVE_STEP,
    //         value: 2
    //     },
    // ]))

}

export const getDefault_updateBeneficiariesObj = (values, contractInfo , companyId) => {
    let { owner, beneficiaries } = contractInfo
    if (isObjEmpty(owner)) {
        owner = { "id": "", "addresses": [{ "id": "" }] }
    }
    if (isArrayEmpty(beneficiaries)) {
        beneficiaries = [{ "id": "", "addresses": [{ "id": "" }] }]
    }
    const arr = getDefault_createContractObj(values , companyId)
    return arr
}

export const _RELATIONSHIPS = [
    {
        label: <FormattedMessage id={getKeyLang(`myself`)} />,
        content: RELATIONSHIP_SELF,
        value: 0,
    },
    {
        label: <FormattedMessage id={getKeyLang(`father`)} />,
        content: RELATIONSHIP_FATHER,
        value: 1,
    },
    {
        label: <FormattedMessage id={getKeyLang(`mother`)} />,
        content: RELATIONSHIP_MOTHER,
        value: 2,
    },
    {
        label: <FormattedMessage id={getKeyLang(`wife`)} />,
        content: RELATIONSHIP_WIFE,
        value:3,
    },
    {
        label: <FormattedMessage id={getKeyLang(`husband`)} />,
        content: RELATIONSHIP_HUSBAND,
        value: 4,
    },
    {
        label: <FormattedMessage id={getKeyLang(`child`)} />,
        content: RELATIONSHIP_CHILD,
        value: 5,
    },
    {
        label: <FormattedMessage id={getKeyLang(`ace`)} />,
        content: RELATIONSHIP_ACE,
        value: 6,
    },
    {
        label: <FormattedMessage id={getKeyLang(`alimony`)} />,
        content: RELATIONSHIP_ALIMONY,
        value: 7,
    },
]
export const QUESTION_TYPE_STRING = 'STRING'
export const QUESTION_TYPE_NUMBER= 'NUMBER'
export const QUESTION_TYPE_DATE = 'DATE'
export const QUESTION_TYPE_BOOLEAN = 'BOOLEAN'
export const QUESTION_TYPE_MULTI = 'MULTI_ANSWER'
export const API_GET_QUESTION = '/nth/personalinsurance/api/happy-life/surveys/template'
export const API_UPLOAD_FILE = '/nth/file/api/upload'
export const API_SURVEYS = '/nth/personalinsurance/api/happy-life/surveys'
export const API_CHECK_VALIDATE_QUESTION = '/nth/personalinsurance/api/happy-life/surveys/validation'
