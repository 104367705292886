import moment from 'moment'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import * as Yup from 'yup'
import * as config from '../../../../../../components/insurance-app/buy-insurances-page/formik-config'
import { getKeyLang } from '../../../../../../configs/insurance-app'

export const IDTypes = [
  {
    label: <FormattedMessage id={getKeyLang(`IDCMND`)} />,
    content: config.ID_TYPE_CMND,
    value: 1
  },
  {
    label: <FormattedMessage id={getKeyLang(`IDCCCD`)} />,
    content: config.ID_TYPE_CCCD,
    value: 2
  },
  {
    label: <FormattedMessage id={getKeyLang(`IDHC`)} />,
    content: config.ID_TYPE_HC,
    value: 3
  },
  {
    label: <FormattedMessage id={getKeyLang(`icType.birthCertificate`)} />,
    content: config.ID_TYPE_GKS,
    value: 4
  }
]

export const sugg_gender = [
  {
    value: '1',
    content: 'MALE',
    label: <FormattedMessage id={getKeyLang(`Male`)} />
  },
  {
    value: '2',
    content: 'FEMALE',
    label: <FormattedMessage id={getKeyLang(`Female`)} />
  }
]

export const KEY_ADDTIONAL_PEOPLE = 'addtinalPeople'
export const KEY_IC_TYPE = 'icType'
export const KEY_IC_NO = 'icNo'
export const KEY_FULLNAME = 'fullname'
export const KEY_DATE_BIRTH = 'dateOfBirth'
export const KEY_PHONE_NUMBER = 'phoneNumber'
export const KEY_EMAIL = 'email'
export const KEY_GENDER = 'gender'
export const KEY_HAVE_BOUGHT_FOR_ME = 'haveBoughtForMe'
export const KEY_RELATIONSHIP = 'relationship'
export const KEY_BANK = 'bank'
export const KEY_STK = 'accountNumber'
export const KEY_ACCOUNT_NUMBER = 'cardNumber'
export const KEY_CLICKED_CLOSE_POPUP = 'clickedPopup'
export const KEY_CITY = config.KEY_CITY
export const KEY_DISTRICT = config.KEY_DISTRICT
export const KEY_WARD = config.KEY_WARD
export const KEY_ADDRESS = config.KEY_ADDRESS
export const KEY_NUMBER_GCNBH_PARENTS = 'numberGCNBHofParent'
export const KEY_NATIONALITY = 'nationality'
export const KEY_PARENTS_BOUGHT_INSURANCE = 'ParentsBoughtInsurance'


export const KEY_NAME_BENEFICIARY = 'nameBeneficiary'
export const KEY_IC_TYPE_BENEFICIARY = 'icTypeBeneficiary'
export const KEY_IC_NO_BENEFICIARY = 'icnoBeneficiary'
export const KEY_INSURANCE_TYPE ='insuredType'
export const KEY_FILE_NAME = "filename"
export const KEY_FILE_TYPE = "fileType"
export const KEY_FILE_CONTENT = 'content'
export const KEY_INFO_SELF = 'infoSelf'
export const KEY_IS_SELF = 'isMySelf'
export const addtionalPeopleInitValue = {
  [KEY_IC_TYPE]: '',
  [KEY_IC_NO]: '',
  [KEY_FULLNAME]: '',
  [KEY_DATE_BIRTH]: '',
  [KEY_GENDER]: '',
  [KEY_NUMBER_GCNBH_PARENTS]: '',
  [KEY_RELATIONSHIP]: '',
  [KEY_NATIONALITY]: '',
  [KEY_INSURANCE_TYPE] : '',
  [KEY_PARENTS_BOUGHT_INSURANCE] : false,
  [KEY_IS_SELF] : false
}

export const initialValues = {
  [KEY_IC_NO]: '',
  [KEY_FULLNAME]: '',
  [KEY_PHONE_NUMBER]: '',
  [KEY_EMAIL]: '',
  [KEY_DATE_BIRTH]: '',
  [KEY_CLICKED_CLOSE_POPUP]: false,
  [KEY_CITY]: '',
  [KEY_DISTRICT]: '',
  [KEY_WARD]: '',
  [KEY_ADDRESS]: '',
  [KEY_ADDTIONAL_PEOPLE]: [{ ...addtionalPeopleInitValue }]
}

export const validate = (values) => {}
export const nameRegex =
  /^([ÀÁÂÃÈÉÊẾÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêếềìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹýA-Za-z_. ])+$/g
export const validationSchema = Yup.object().shape({
  [KEY_ADDTIONAL_PEOPLE]: Yup.array().of(
    Yup.object().shape({
      [KEY_RELATIONSHIP]: Yup.string().required(config.ALERT_EMPTY),
      [KEY_FULLNAME]: Yup.string()
        .matches(nameRegex, config.ALERT_INVALID)
        .required(config.ALERT_EMPTY),
      [KEY_GENDER]: Yup.string().required(config.ALERT_EMPTY),
      [KEY_NATIONALITY]: Yup.string().required(config.ALERT_EMPTY),
      [KEY_FILE_CONTENT]: Yup.string().when(KEY_DATE_BIRTH, (value) => {
        const age = moment().diff(moment(value).format('YYYY-MM-DD'), 'years')
        if (age < 18) {
          return Yup.string().required("Người dưới 18 tuổi phải tải lên giấy khai sinh")
        }
      })
    })
  ),
  ...config.getDefault_validateSchemaIcNo(KEY_IC_NO, KEY_IC_TYPE),
  [KEY_FULLNAME]: Yup.string()
    .matches(nameRegex, config.ALERT_INVALID)
    .required(config.ALERT_EMPTY),
  [KEY_PHONE_NUMBER]: Yup.string()
    .matches(config.phoneNumberRegex, config.ALERT_INVALID)
    .required(config.ALERT_EMPTY),
  [KEY_EMAIL]: Yup.string()
    .matches(config.emailRegex, config.ALERT_INVALID)
    .required(config.ALERT_EMPTY),
  [KEY_DATE_BIRTH]: Yup.string().required(config.ALERT_EMPTY),
  [KEY_CITY]: Yup.string().required(config.ALERT_EMPTY),
  [KEY_DISTRICT]: Yup.string().required(config.ALERT_EMPTY),
  [KEY_WARD]: Yup.string().required(config.ALERT_EMPTY),
  [KEY_ADDRESS]: Yup.string().required(config.ALERT_EMPTY),
  [KEY_IC_NO]: Yup.string().required(config.ALERT_EMPTY)
})
