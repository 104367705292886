import { HttpClient, BaseAppUltils } from 'base-app'
import * as API from '../../configs/insurance-app'
import { getBasePath } from '../../ultity'
import {VEHICLE_INSURANCE_URL} from "../../configs/insurance-app";
class BuyInsuranceCar {
    static getMinMaxSeatLoadVehicleType(formData) {
        const { inonType, businessStatus } = formData
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID(),
        }
        return HttpClient.get(`${API.API_VEHICLE_INSUR_MIN_MAX_SEATS_LOADS}`, {
            params: {
                uuid: BaseAppUltils.generateUUID(),
                businessStatus: businessStatus,
                inonType: inonType,
            },
            headers: headers,
            isBackgroundRequest: true
        })
    }

    static updateCompanyId(values) {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID(),
        }
        return HttpClient.put(`${getBasePath(API.NAME_BUY_INSURANCES_CAR)}${API.API_CONTRACTS_COMPANY_ID}`, values, {
            headers: headers
        })
    }

    static checkInfoVehicle(idNum) {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID(),
        }
        return HttpClient.get(`${API.API_CHECK_INFO_VEHICLE}/${idNum}`, {
            params: { date: new Date().getMilliseconds() },
            headers: headers
        })
    }

    static createConstract(values) {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID(),
        }
        return HttpClient.post(`${API.API_CREATE_CONSTRACT}`, values, {
            headers: headers
        })
    }

    static updateConstract(values) {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID(),
        }
        return HttpClient.put(`${API.API_CREATE_CONSTRACT}`, values, {
            headers: headers
        })
    }

    static getConstractInfo(id) {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID(),
        }
        return HttpClient.get(`${API.API_GET_CONSTRACT}/${id}`, {
            params: { date: new Date().getMilliseconds() },
            headers: headers
        })
    }

    static getContactByContractId(id) {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID(),
        }
        return HttpClient.get(`${API.API_GET_CONTACT_BY_CONTRACTID}/${id}`, {
            params: { date: new Date().getMilliseconds() },
            headers: headers
        })
    }

    static createVehicle(values) {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID(),
        }
        return HttpClient.post(`${API.API_CREATE_VEHICLE}`, values, {
            headers: headers
        })
    }

    static updateVehicle(values) {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID(),
        }
        return HttpClient.put(`${API.API_CREATE_VEHICLE}`, values, {
            headers: headers
        })
    }

    static createContact(values) {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID(),
        }
        return HttpClient.post(`${API.API_CREATE_CONTACT}`, values, {
            headers: headers
        })
    }

    static updateContact(values) {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID(),
        }
        return HttpClient.put(`${API.API_CREATE_CONTACT}`, values, {
            headers: headers
        })
    }

    static checkInfoContact(idContact) {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID(),
        }
        return HttpClient.get(`${API.API_CHECK_INFO_CONTACT_BY_ID}/${idContact}`, {
            params: { date: new Date().getMilliseconds() },
            headers: headers
        })
    }

    static getContact(idContact) {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID(),
        }
        return HttpClient.get(`${API.API_GET_CONTACT}/${idContact}`, {
            params: { date: new Date().getMilliseconds() },
            headers: headers
        })
    }

    static updateInsurance(values) {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID(),
        }
        return HttpClient.put(`${API.API_UPDATE_INSURANCE}`, values, {
            headers: headers
        })
    }

    static updateInsuranceAddon(values) {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID(),
        }
        return HttpClient.put(`${API.API_UPDATE_INSURANCE_ADDONS}`, values, {
            headers: headers
        })
    }

    static getCarManufacturers() {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID(),
        }
        return HttpClient.get(`${API.API_GET_CAR_MANUFACTURERS}`, {
            params: { date: new Date().getMilliseconds() },
            headers: headers
        })
    }

    static getAllBank() {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID(),
        }
        return HttpClient.get(`${API.API_GET_ALL_BANK}`, {
            params: { date: new Date().getMilliseconds() },
            headers: headers
        })
    }

    static getCarVehicle() {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID(),
        }
        return HttpClient.get(`${API.API_GET_CAR_VEHICLE}`, {
            params: { date: new Date().getMilliseconds() },
            headers: headers
        })
    }

    static getContractFee(id, companyIds) {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID(),
        }

        const promises = companyIds.map(companyId => {
            return HttpClient.get(`${API.API_GET_CONTRACT_FEE}/${id}`, {
                params: { companyId: companyId, date: new Date().getMilliseconds() },
                headers: headers
            });
        });

        return Promise.all(promises)
        .then(results => {
            const filteredResults = results
            .filter(result =>
                result.status === 200 &&
                Object.keys(result.data).length > 0 &&
                result.data.hasOwnProperty('totalFee')
            )
            .map(result => result.data);

            if (filteredResults.length > 0) {
                return { status: 200, data: filteredResults };
            } else {
                return { status: 400, data: null };
            }
        });
    }

    static payConstract(id, values) {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID(),
        }
        return HttpClient.post(`${API.API_PAY_CONSTRACT}/${id}`, values, {
            headers: headers
        })
    }

    static completeConstract(values) {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID(),
        }
        return HttpClient.put(`${API.API_COMPLETE_CONSTRACT}`, values, {
            headers: headers
        })
    }

    static approvedConstract(values) {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID(),
        }
        return HttpClient.put(`${API.API_APPROVED_CONSTRACT}`, values, {
            headers: headers
        })
    }

    static getCity() {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID(),
        }
        return HttpClient.get(`${API.API_GET_CITY}`, {
            params: { date: new Date().getMilliseconds() },
            headers: headers
        })
    }

    static getDistricts() {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID(),
        }
        return HttpClient.get(`${API.API_GET_DISTRICTS}`, {
            params: { date: new Date().getMilliseconds() },
            headers: headers
        })
    }

    static getWards() {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID(),
        }
        return HttpClient.get(`${API.API_GET_WARDS}`, {
            params: { date: new Date().getMilliseconds() },
            headers: headers
        })
    }

    static getConstractInfo(id) {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID(),
        }
        return HttpClient.get(`${API.API_GET_CONSTRACT}/${id}`, {
            params: { date: new Date().getMilliseconds() },
            headers: headers
        })
    }

    static getBankTransferInfo() {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID(),
        }
        return HttpClient.get(`${API.API_GET_BANK_TRANSFER_INFO}`, {
            params: { date: new Date().getMilliseconds() },
            headers: headers
        })
    }

    static uploadFile(formData) {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID(),
            'Content-Type': undefined
        }
        return HttpClient.post(`${API.API_UPLOAD_FILE}`, formData, {
            headers: headers
        })
    }

    static getTemplateVehicleList() {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID(),
        }
        return HttpClient.get(`${API.API_GET_VEHICLE_TEMPLATE_LIST}`, {
            params: { date: new Date().getMilliseconds() },
            headers: headers
        })
    }

    static confirmConstract(values) {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID(),
        }
        return HttpClient.post(`${API.API_CONFIRM_CONSTRACT}`, values, {
            headers: headers,
            isBackgroundRequest: true
        })
    }

    static getConfigAccount() {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID(),
        }
        return HttpClient.get(`${API.API_GET_CONFIG_ACCOUNT}`, {
            params: { date: new Date().getMilliseconds() },
            headers: headers
        })
    }

    static getConfigFeeBHVC(companyId) {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID(),
        }
        if (companyId) {
            return HttpClient.get(`${API.API_VEHICLE_INSURANCEFEE_CONFIG}/${companyId}`, {
                params: { date: new Date().getMilliseconds() },
                headers: headers
            })
        }
        return HttpClient.get(`${API.API_VEHICLE_INSURANCEFEE_CONFIG}`, {
            params: { date: new Date().getMilliseconds() },
            headers: headers
        })
    }

    static getAddTerm(value) {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID(),
        }
        return HttpClient.get(`${API.API_GET_ADD_TERMS}/${value}`, {
            params: { date: new Date().getMilliseconds() },
            headers: headers
        })
    }

    static getMyDebtAccount() {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID(),
        }
        return HttpClient.get(`${API.API_GET_MY_DEBT_ACCOUNT}`, {
            params: { uuid: BaseAppUltils.generateUUID() },
            headers: headers,
            isBackgroundRequest: true
        })
    }

    static getCompany() {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID(),
        }
        return HttpClient.get(API.API_GET_COMPANY, {
            headers: headers
        })
    }

    static tranferSallerId(data) {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID(),
        }
        return HttpClient.put(API.API_TRANFER_SALLER_CONTRACT, data, {
            headers: headers
        })
    }

    static updateReasonContract(data) {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID(),
        }
        return HttpClient.post(API.API_UPDATE_STATUS_REASON_BUY_INSURANCECE, data, {
            headers: headers
        })
    }

    static getAssets(page = 1) {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID(),
        }
        return HttpClient.get(`${API.API_ASSETS}?assetCode=&number=${page}&size=20`, {
            headers: headers,
            isBackgroundRequest: true
        })
    }

    static getAssetsByAssetcode(assetCode) {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID(),
        }
        return HttpClient.get(`${API.API_ASSETS}?assetCode=${assetCode}&number=1&size=20`, {
            params: { uuid: BaseAppUltils.generateUUID() },
            headers: headers,
            isBackgroundRequest: true
        })
    }

    static getCompanyEnableCar() {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID()
        }

        return HttpClient.get(VEHICLE_INSURANCE_URL.PATH.GET_COMPANY_ENABLE_CAR, {
            headers: headers
        })
    }

    static getCompanyEnableCarPublic() {
        const headers = {
            clientMessageId: BaseAppUltils.generateUUID()
        }

        return HttpClient.get(VEHICLE_INSURANCE_URL.PATH.GET_COMPANY_ENABLE_CAR_PUBLIC, {
            headers: headers
        })
    }

}

export default BuyInsuranceCar

